import React from 'react'
import DashHeader from '../Components/DashHeader'
import Sidebar from '../Components/DashSidebar'
import ViewDep from '../Components/ViewDep'
import RealNav from '../Components/RealNav'

function ViewDeposits() {
  return (
    
    <div className="container-scroller">
     <RealNav className="container-fluid page-body-wrapper"/>
     <RealNav />
      <ViewDep />
    </div>
  )
}

export default ViewDeposits
