import React, { useState } from 'react';

// Animated logo component
function AnimatedLogo() {
  return (
    <div style={logoStyles.container}>
      <img src="/LOGO.png" alt="Logo" style={logoStyles.image} />
    </div>
  );
}

function SignupFinish() {
  const [accountType, setAccountType] = useState('personal');

  return (
    <div style={styles.container}>
      <AnimatedLogo />
      <h1 style={styles.heading}>Finish The Account Setup</h1>
      <form style={styles.form}>
        <div style={styles.formGroup}>
          <label style={styles.label} htmlFor="nationalId">National ID</label>
          <input style={styles.input} type="text" id="nationalId" name="nationalId" required />
        </div>
        <div style={styles.formGroup}>
          <label style={styles.label} htmlFor="city">City</label>
          <input style={styles.input} type="text" id="city" name="city" required />
        </div>
        
        <div style={styles.formGroup}>
          <label style={styles.label} htmlFor="idPhoto">ID Photo</label>
          <input style={styles.input} type="file" id="idPhoto" name="idPhoto" accept="image/*" required />
        </div>
        <div style={styles.formGroup}>
          <label style={styles.label} htmlFor="userPhoto">User Photo (optional)</label>
          <input style={styles.input} type="file" id="userPhoto" name="userPhoto" accept="image/*" />
        </div>
        <div style={styles.formGroup}>
          <label style={styles.label} htmlFor="accountType">Account Type</label>
          <select
            style={styles.input}
            id="accountType"
            name="accountType"
            value={accountType}
            onChange={e => setAccountType(e.target.value)}
            required
          >
            <option value="personal">Personal</option>
            <option value="business">Business</option>
          </select>
        </div>
        <div style={styles.formGroup}>
          <label style={styles.label} htmlFor="accountNumber">Account Number</label>
          <input style={styles.input} type="text" id="accountNumber" name="accountNumber" required />
        </div>
        <button style={styles.button} type="submit">Submit</button>
      </form>
    </div>
  );
}

const logoStyles = {
  container: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  image: {
    width: '100px',
    height: '100px',
    animation: 'pulse 2s infinite',
  },
  '@keyframes pulse': {
    '0%': { transform: 'scale(1)' },
    '50%': { transform: 'scale(1.1)' },
    '100%': { transform: 'scale(1)' },
  },
};

const styles = {
  container: {
    maxWidth: '500px',
    margin: '0 auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    border: '1px solid #ccc',
    borderRadius: '5px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  },
  heading: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  formGroup: {
    marginBottom: '15px',
  },
  label: {
    marginBottom: '5px',
    fontWeight: 'bold',
  },
  input: {
    width: '100%',
    padding: '8px',
    boxSizing: 'border-box',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#007BFF',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
  }
};

export default SignupFinish;
