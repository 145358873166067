import React, { useState } from 'react';
import {
  Button,
  Modal,
  Box,
  Typography,
  TextField,
  CircularProgress,
  Snackbar,
  Alert
} from '@mui/material';
import axios from 'axios';
import Cookies from 'js-cookie';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const Password = () => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    old: '',
    new: '',
    confirm: '',
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [notification, setNotification] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setError('');
    setSuccess(false);
    setFormData({
      old: '',
      new: '',
      confirm: '',
    });
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess(false);
    setLoading(true);

    try {
      const authToken = Cookies.get('authToken');
      if (!authToken) {
        setError('No authentication token found. Please log in.');
        setLoading(false);
        return;
      }

      if (formData.new !== formData.confirm) {
        setError('New password and confirmation do not match.');
        setLoading(false);
        return;
      }

      const response = await axios.post('https://api.nkolozi.com/api/Client/change-password', formData, {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        setSuccess(true);
        setNotification('Password changed successfully!');
        handleClose();
      } else {
        setError('Failed to change password');
      }
    } catch (error) {
      console.error('Submit password error:', error.response?.data || error.message);
      setError(`Error: ${error.response?.data?.message || error.message || 'An unexpected error occurred'}`);
    }
    setLoading(false);
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Change Password
      </Button>
      <Modal open={open} onClose={handleClose} aria-labelledby="password-change-modal-title" aria-describedby="password-change-modal-description">
        <Box sx={style}>
          <Typography id="password-change-modal-title" variant="h6" component="h2">
            Change Password
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              name="old"
              label="Old Password"
              type="password"
              fullWidth
              margin="normal"
              value={formData.old}
              onChange={handleChange}
              required
            />
            <TextField
              name="new"
              label="New Password"
              type="password"
              fullWidth
              margin="normal"
              value={formData.new}
              onChange={handleChange}
              required
            />
            <TextField
              name="confirm"
              label="Confirm New Password"
              type="password"
              fullWidth
              margin="normal"
              value={formData.confirm}
              onChange={handleChange}
              required
            />
            {error && (
              <Typography color="error" variant="body2" style={{ marginTop: '10px' }}>
                {error}
              </Typography>
            )}
            {loading ? (
              <CircularProgress style={{ marginTop: '20px' }} />
            ) : (
              <Button variant="contained" color="primary" type="submit" style={{ marginTop: '20px' }}>
                Change Password
              </Button>
            )}
          </form>
        </Box>
      </Modal>

      <Snackbar
        open={!!notification}
        autoHideDuration={6000}
        onClose={() => setNotification('')}
      >
        <Alert onClose={() => setNotification('')} severity={success ? 'success' : 'error'}>
          {notification}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Password;
