import React from 'react'
import DashHeader from '../Components/DashHeader'
import DashSideBar from '../Components/DashSidebar'
import FullTransComp from '../Components/FullTransComp'
import RealNav from '../Components/RealNav'
import RealSide from '../Components/RealSide'

function FullTrans() {
  return (
    <div className="container-scroller">
     <RealNav className="container-fluid page-body-wrapper"/>
     <RealSide className="main-panel"/>
     <FullTransComp className="content-wrapper"/>
     
    </div>
  )
}

export default FullTrans
