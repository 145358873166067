import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';

function DashPending() {
  const [transactions, setTransactions] = useState([]);
  const authToken = Cookies.get('authToken');

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await fetch('https://api.nkolozi.com/api/Client/client-transactions', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
          }
        });

        const result = await response.json();
        if (response.ok) {
          setTransactions(result.data);
        } else {
          console.log('Failed to fetch transactions:', result);
        }
      } catch (error) {
        console.log('An error occurred:', error);
      }
    };

    fetchTransactions();
  }, [authToken]);

  // Function to determine progress percentage and color
  const getProgress = (status) => {
    switch (status) {
      case 'initiated':
        return { percentage: 15, color: 'bg-warning' };
      case 'waiting':
        return { percentage: 50, color: 'bg-info' };
      case 'approved':
        return { percentage: 100, color: 'bg-success' };
      case 'rejected':
        return { percentage: 100, color: 'bg-danger' };
      default:
        return { percentage: 0, color: 'bg-secondary' };
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body px-0 overflow-auto">
              <h4 className="card-title pl-4">Remittance Transactions</h4>
              <div className="mt-4">
                {transactions.length > 0 ? (
                  transactions.map(transaction => {
                    const { percentage, color } = getProgress(transaction.status);
                    return (
                      <div key={transaction.id} className="mb-4">
                        <div className="d-flex flex-column mb-2">
                          <h6 className="mb-0">{transaction.transaction_id}</h6>
                          <span className={`badge badge-${color}`}>
                            {transaction.status.charAt(0).toUpperCase() + transaction.status.slice(1)}
                          </span>
                        </div>
                        <div className="progress" style={{ maxWidth: '400px', margin: '0 auto' }}>
                          <div
                            className={`progress-bar ${color}`}
                            role="progressbar"
                            style={{ width: `${percentage}%` }}
                            aria-valuenow={percentage}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            {percentage}%
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="text-center py-4">
                    <h5>No Transactions Yet</h5>
                  </div>
                )}
              </div>
              <Link to="/full-trans" className="text-black mt-3 d-block pl-4">
                <span className="font-weight-medium h6">View all transaction history</span>
                <i className="mdi mdi-chevron-right" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashPending;
