import React from 'react';

const mainPanelStyle = {
  padding: '20px',
  fontFamily: 'Arial, sans-serif'
};

const headerStyle = {
  fontSize: '28px',
  marginBottom: '20px',
  color: '#2A4B8D', // Dark blue color for the header
  textAlign: 'center'
};

const notificationContainerStyle = {
  width: '100%',
  borderCollapse: 'collapse',
  marginBottom: '20px'
};

const notificationItemStyle = (type) => ({
  border: '1px solid #ddd',
  padding: '10px',
  marginBottom: '10px',
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: type === 'info' ? '#e7f3fe' :
                  type === 'warning' ? '#fff8e5' :
                  type === 'success' ? '#e6f7e5' :
                  '#f8d7da',
  color: type === 'info' ? '#31708f' :
         type === 'warning' ? '#856404' :
         type === 'success' ? '#155724' :
         '#721c24'
});

const notificationTitleStyle = {
  fontSize: '16px',
  fontWeight: 'bold',
  marginRight: '10px'
};

const notificationTimeStyle = {
  fontSize: '14px',
  color: '#999'
};

function DashNotifications() {
  return (
    <div style={mainPanelStyle}>
      <h1 style={headerStyle}>Dash Notifications</h1>
      <div style={notificationContainerStyle}>
        <div style={notificationItemStyle('info')}>
          <span style={notificationTitleStyle}>New update available</span>
          <span style={notificationTimeStyle}>2024-06-18 12:34 PM</span>
        </div>
        <div style={notificationItemStyle('warning')}>
          <span style={notificationTitleStyle}>Server maintenance at 3 PM</span>
          <span style={notificationTimeStyle}>2024-06-18 11:00 AM</span>
        </div>
        <div style={notificationItemStyle('success')}>
          <span style={notificationTitleStyle}>Profile updated successfully</span>
          <span style={notificationTimeStyle}>2024-06-17 09:15 AM</span>
        </div>
        <div style={notificationItemStyle('danger')}>
          <span style={notificationTitleStyle}>Login attempt failed</span>
          <span style={notificationTimeStyle}>2024-06-16 03:45 PM</span>
        </div>
        {/* Add more notifications as needed */}
      </div>
    </div>
  );
}

export default DashNotifications;
