import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import Cookies from 'js-cookie';
import { Paper, Typography } from '@mui/material';

function AccountsComp() {
  const [accounts, setAccounts] = useState([]);
  const [currencies, setCurrencies] = useState({});
  const [loading, setLoading] = useState(true);

  // Get user info from localStorage
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const userId = userInfo ? userInfo.id : ''; // Get user ID from stored user info

  // Get auth token from cookies
  const authToken = Cookies.get('authToken');

  useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const response = await axios.get('https://api.nkolozi.com/api/Client/currencies', {
          headers: {
            'Authorization': `Bearer ${authToken}`,
          },
        });
        const currencyData = response.data.data.reduce((acc, currency) => {
          acc[currency.id] = currency.name;
          return acc;
        }, {});
        setCurrencies(currencyData);
      } catch (error) {
        console.error('Error fetching currencies:', error);
      }
    };

    const fetchAccounts = async () => {
      if (!authToken || !userId) {
        console.error('No auth token or user ID found');
        return;
      }

      try {
        const response = await axios.get(`https://api.nkolozi.com/api/Client/client-accounts/${userId}`, {
          headers: {
            'Authorization': `Bearer ${authToken}`,
          },
        });
        if (response.data && Array.isArray(response.data.client_accounts)) {
          setAccounts(response.data.client_accounts);
        } else {
          console.error('Unexpected response format:', response.data);
          setAccounts([]);
        }
      } catch (error) {
        console.error('Error fetching accounts:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCurrencies();
    fetchAccounts();
  }, [authToken, userId]);

  // Function to format the date
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div style={{marginLeft:"30%"}} className="container mt-5">
      <div>
        <h1></h1> <br />
      </div>
      <Paper
        sx={{
          padding: '3px 10px',
          marginBottom: '20px',
          width: 'fit-content', // Makes the paper fit the content
          backgroundColor: '#c5401f', // Set background color
          '@keyframes slide-up': {
            '0%': {
              transform: 'translateY(20px)',
              opacity: 0,
            },
            '100%': {
              transform: 'translateY(0)',
              opacity: 1,
            },
          },
          animation: 'slide-up 0.5s ease-in-out',
        }}
      >
        <Typography variant="h5" gutterBottom sx={{ color: '#fff' }}>
          Clients account
        </Typography>
      </Paper>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
             
              <th>Currency</th>
              <th>Current Balance</th>
              <th>Available Balance</th>
             
              <th>Created At</th>
              <th>Updated At</th>
            </tr>
          </thead>
          <tbody>
            {accounts.length > 0 ? (
              accounts.map((account) => (
                <tr key={account.id}>
                  
                  <td>{currencies[account.currency_id] || 'Unknown Currency'}</td>
                  <td>{account.current_balance}</td>
                  <td>{account.available_balance}</td>
                 
                  <td>{formatDate(account.created_at)}</td>
                  <td>{formatDate(account.updated_at)}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7">No accounts available</td>
              </tr>
            )}
          </tbody>
        </Table>
      )}
    </div>
  );
}

export default AccountsComp;
