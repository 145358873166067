import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Modal, Button, Spinner } from 'react-bootstrap';

export default function Login() {
  const [contact, setContact] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const data = {
      phone1: contact,
      password: password,
    };

    try {
      const response = await fetch('https://api.nkolozi.com/api/client_login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();
      setLoading(false);

      if (response.ok) {
        console.log('Login successful:', result);

        const authToken = result.data; // Extract the token from the response

        // Store the token in a cookie
        Cookies.set('authToken', authToken, { expires: 7 }); // Set the cookie to expire in 7 days

        // Show OTP modal
        setShowOTPModal(true);
      } else {
        console.log('Login failed:', result);
        setErrorMessage(result.message || 'Login failed');
      }
    } catch (error) {
      setLoading(false);
      console.log('An error occurred:', error);
      setErrorMessage(error.message || 'An error occurred. Please try again.');
    }
  };

  const handleOTPSubmit = async () => {
    setOtpLoading(true);

    const otpData = {
      number: otp,
      user_id: contact,
    };

    try {
      const otpResponse = await fetch('https://api.nkolozi.com/api/verify-otp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(otpData),
      });

      const otpResult = await otpResponse.json();
      setOtpLoading(false);

      if (otpResponse.ok) {
        console.log('OTP verification successful:', otpResult);

        // Now use the authToken to fetch user information
        const authToken = Cookies.get('authToken');
        const userResponse = await fetch('https://api.nkolozi.com/api/Client/client', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
          },
        });

        const userResult = await userResponse.json();
        if (userResponse.ok) {
          console.log('User information:', userResult);

          // Store user information in localStorage
          localStorage.setItem('userInfo', JSON.stringify(userResult.data));

          // Navigate to the Dashboard
          navigate('/Dashboard');
        } else {
          console.log('Failed to retrieve user information:', userResult);
          setErrorMessage(userResult.message || 'Failed to retrieve user information');
        }
      } else {
        console.log('OTP verification failed:', otpResult);
        setErrorMessage(otpResult.message || 'OTP verification failed');
      }
    } catch (error) {
      setOtpLoading(false);
      console.log('An error occurred:', error);
      setErrorMessage(error.message || 'An error occurred. Please try again.');
    }
  };

  return (
    <>
      <link
        rel="stylesheet"
        href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"
      />

<style>{`
    body, html {
      height: 100%;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .video-bg {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
    }
    .card {
      border-radius: 20px;
      background-color: rgba(255, 255, 255, 0.9);
      z-index: 1;
    }

    /* Mobile media query */
    @media (max-width: 768px) {
      .video-bg {
        
      }
    }
  `}</style>

      <div className="container-fluid vh-100 d-flex justify-content-center align-items-center">
        <video autoPlay loop muted className="video-bg">
          <source src="/login4.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="card shadow-lg p-4" style={{ maxWidth: '400px', width: '100%' }}>
          <div className="card-body">
            <h3 className="card-title text-center mb-4">Nkolozi Money Transfer</h3>
            {errorMessage && (
              <div className="alert alert-danger" role="alert">
                {errorMessage}
              </div>
            )}
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="contact">Phone Number</label>
                <input
                  type="text"
                  className="form-control"
                  id="contact"
                  placeholder="e.g +263777777777"
                  value={contact}
                  onChange={(e) => setContact(e.target.value)}
                  disabled={loading}
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  disabled={loading}
                />
              </div>
              <button type="submit" className="btn btn-primary btn-block mt-3" disabled={loading}>
                {loading ? <Spinner as="span" animation="border" size="sm" /> : 'Login'}
              </button>
              <div className="text-center mt-3">
                <Link to="/forgot" className="text-secondary">
                  Forgot password?
                </Link>
              </div>
              <div className="text-center mt-3">
                <Link to="/signup" className="text-secondary">
                  Don't have an Account? Signup
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Modal show={showOTPModal} onHide={() => setShowOTPModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Enter OTP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="otp">OTP</label>
            <input
              type="text"
              className="form-control"
              id="otp"
              placeholder="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              disabled={otpLoading}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowOTPModal(false)} disabled={otpLoading}>
            Close
          </Button>
          <Button variant="primary" onClick={handleOTPSubmit} disabled={otpLoading}>
            {otpLoading ? <Spinner as="span" animation="border" size="sm" /> : 'Verify OTP'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
