import React from 'react'
import DashHeader from '../Components/DashHeader'
import DashSidebar from '../Components/DashSidebar'
import AccountsComp from '../Components/AccountsComp'
import RealNav from '../Components/RealNav'
import RealSide from '../Components/RealSide'

function Accounts() {
  return (
    <div className="container-scroller">
      <RealNav className="container-fluid page-body-wrapper"/>
      <RealSide className="main-panel"/>
      <AccountsComp className="content-wrapper"/>
    </div>
  )
}

export default Accounts
