import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DashPending from '../Components/DashPending';
import Cookies from 'js-cookie';
import UserDash from '../Components/UserDash';
import RealSide from '../Components/RealSide';
import RealNav from '../Components/RealNav';

export default function Dashboard() {
  const [userInfo, setUserInfo] = useState({});
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    const storedUserInfo = localStorage.getItem('userInfo');
    if (storedUserInfo) {
      setUserInfo(JSON.parse(storedUserInfo));
    }

    const fetchBalance = async () => {
      try {
        const authToken = Cookies.get('authToken');
        const response = await fetch('https://api.nkolozi.com/api/Client/client-accounts', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
          },
        });

        if (response.ok) {
          const result = await response.json();
          console.log('API response:', result);

          const account = result.data.find(account => account.user_id);
          if (account) {
            setBalance(account.current_balance);
          } else {
            console.log('No account found for the given user_id.');
          }
        } else {
          console.log('Failed to retrieve balance:', response.statusText);
        }
      } catch (error) {
        console.log('An error occurred:', error.message);
      }
    };

    if (Cookies.get('authToken')) {
      fetchBalance();
    }
  }, []);

  return (
    <>
      <RealNav />
      <div className="d-flex">
        <RealSide />
        <main className="main flex-grow-1" id="main" style={{ padding: '20px' }}>
          <div className="page-header flex-wrap">
            <h3 className="mb-0">
              <span className="pl-0 h6 pr-sm-2 text-muted d-inline-block">Account Status:</span>
              <span className="badge badge-danger" style={{ backgroundColor: 'red' }}>{userInfo.status}</span>
            </h3>
          </div>

          <div className="card mb-4">
            <div className="card-body">
              <UserDash />
              <div className="row my-3">
                <h1>Transaction Progress</h1>
              </div>
              <DashPending />
              <div className="row">
                <div className="col-8 col-sm-8"></div>
                <div className="col-4 col-sm-4">
                  <p className="mb-0 text-muted">Total Sent</p>
                  <h5 className="d-inline-block survey-value mb-0">00</h5>
                  <p className="d-inline-block text-danger mb-0">last 00</p>
                </div>
              </div>
            </div>
          </div>

          <footer className="footer mt-auto">
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <span className="text-muted text-center text-sm-left d-block">
                Copyright © Nkolozi Money transfer 2024
              </span>
              <span className="text-center mt-1 mt-sm-0">
                <a href="https://www.nkolozi.com/" target="_blank" rel="noopener noreferrer">Nkolozi Money Transfer</a>
                nkolozi.com
              </span>
            </div>
          </footer>
        </main>
      </div>
    </>
  );
}
