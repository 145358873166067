import React from 'react'
import ConvertComp from '../Components/ConvertComp'
import DashSidebar from '../Components/DashSidebar'
import DashHeader from '../Components/DashHeader'
import RealNav from '../Components/RealNav'
import RealSide from '../Components/RealSide'

function Convert() {
  return (
    <div className="container-scroller">
    <RealNav className="container-fluid page-body-wrapper"/>
    <RealSide className="main-panel"/>
     <ConvertComp className="content-wrapper"/> 
    </div>
  )
}

export default Convert
