import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Container, Row, Col, Card, Spinner, Alert } from 'react-bootstrap';
import Cookies from 'js-cookie';
import { Paper, Typography } from '@mui/material';   

function ConvertComp() {
  const [currencies, setCurrencies] = useState([]);
  const [fromCurrency, setFromCurrency] = useState('');
  const [toCurrency, setToCurrency] = useState('');
  const [amount, setAmount] = useState('');
  const [conversionResult, setConversionResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [resultMessage, setResultMessage] = useState('');
  const [resultVariant, setResultVariant] = useState('success');

  useEffect(() => {
    const authToken = Cookies.get('authToken');
    axios.get('https://api.nkolozi.com/api/Client/currencies', {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(response => {
        setCurrencies(response.data.data);
      })
      .catch(error => {
        console.error('There was an error fetching the currencies!', error);
      });
  }, []);

  const handleConvert = (e) => {
    e.preventDefault();
    setLoading(true);
    setConversionResult(null);

    const data = {
      from: parseInt(fromCurrency),
      to: parseInt(toCurrency),
      amount: amount,
    };

    const authToken = Cookies.get('authToken');
    axios.post('https://api.nkolozi.com/api/Client/change-currency', data, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        setConversionResult(response.data);
        setResultMessage('Currency conversion successful!');
        setResultVariant('success');
      })
      .catch(error => {
        console.error('There was an error converting the currency!', error);
        setResultMessage('Currency conversion failed.');
        setResultVariant('danger');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCurrencyNameById = (id) => {
    const currency = currencies.find((currency) => currency.id === parseInt(id));
    return currency ? currency.name : '';
  };

  return (
    <Container style={{ marginTop: '90px' }}>
      <Row className="justify-content-center">
        <Col md={6}>
          <Paper
            sx={{
              padding: '3px 10px', 
              marginBottom: '20px',
              width: 'fit-content', 
              backgroundColor: '#c5401f',
              '@keyframes slide-up': {
                '0%': {
                  transform: 'translateY(20px)',
                  opacity: 0,
                },
                '100%': {
                  transform: 'translateY(0)',
                  opacity: 1,
                },
              },
              animation: 'slide-up 0.5s ease-in-out',
            }}
          >
            <Typography variant="h5" gutterBottom sx={{ color: '#fff' }}>
              Convert Currency
            </Typography>
          </Paper>
          <Form onSubmit={handleConvert}>
            <Form.Group controlId="fromCurrency">
              <Form.Label>From Currency</Form.Label>
              <Form.Control
                as="select"
                value={fromCurrency}
                onChange={(e) => setFromCurrency(e.target.value)}
                disabled={loading}
              >
                <option value="">Select Currency</option>
                {currencies.map((currency) => (
                  <option key={currency.id} value={currency.id}>
                    {currency.name} - {currency.country}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="toCurrency">
              <Form.Label>To Currency</Form.Label>
              <Form.Control
                as="select"
                value={toCurrency}
                onChange={(e) => setToCurrency(e.target.value)}
                disabled={loading}
              >
                <option value="">Select Currency</option>
                {currencies.map((currency) => (
                  <option key={currency.id} value={currency.id}>
                    {currency.name} - {currency.country}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="amount">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder="Enter amount to convert"
                disabled={loading}
              />
            </Form.Group>

            <Button variant="primary" type="submit" className="w-100" disabled={loading}>
              {loading ? <Spinner as="span" animation="border" size="sm" /> : 'Convert'}
            </Button>
          </Form>

          {conversionResult && (
            <Card className="mt-4">
              <Card.Body>
                <Alert variant={resultVariant} className="text-center">
                  {resultMessage}
                </Alert>
                <h5>Conversion Result</h5>
                <p>From: {getCurrencyNameById(fromCurrency)}</p>
                <p>To: {getCurrencyNameById(toCurrency)}</p>
                <p>Amount: {amount}</p>
                <p>Converted Amount: {conversionResult.convertedAmount}</p>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default ConvertComp;
