import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Main from './Screens/Main';
import Login from './Auth/Login';
import Signup from './Auth/Signup';
import ForgotPassword from './Auth/FogortPassword';
import Dashboard from './Screens/Dashboard';
import SendMoney from './Screens/SendMoney';
import TransHistory from './Screens/TransHistory';
import Support from './Screens/Support';
import Notifications from './Screens/Notifications';
import Signout from './Auth/Signout';
import SignupFinish from './Auth/SignupFinish';
import FinishSignup from './Screens/FinishSignup';
import UpdateProf from './Screens/UpdateProf';
import AddBeneficiary from './Screens/AddBeneficiary';
import PrivateRoute from './Auth/PrivateRoute';
import Transact from './Screens/Transact';
import Accounts from './Screens/Accounts';
import Deposit from './Screens/Deposit';
import ViewDeposits from './Screens/ViewDeposits';
import Convert from './Screens/Convert';
import FullTrans from './Screens/FullTrans';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/signup-finish" element={<SignupFinish />} />
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route path="/dashboard" element={<PrivateRoute element={<Dashboard />} />} />
        <Route path="/send" element={<PrivateRoute element={<SendMoney />} />} />
        <Route path="/trans" element={<PrivateRoute element={<TransHistory />} />} />
        <Route path="/support" element={<PrivateRoute element={<Support />} />} />
        <Route path="/notifications" element={<PrivateRoute element={<Notifications />} />} />
        <Route path="/signout" element={<PrivateRoute element={<Signout />} />} />
        <Route path="/finish" element={<PrivateRoute element={<FinishSignup />} />} />
        <Route path="/update" element={<PrivateRoute element={<UpdateProf />} />} />
        <Route path="/add-benef" element={<PrivateRoute element={<AddBeneficiary />} />} />
        <Route path="/transact" element={<PrivateRoute element={<Transact />} />} />
        <Route path="/accounts" element={<PrivateRoute element={<Accounts/>} />} />
        <Route path="/deposit" element={<PrivateRoute element={<Deposit/>} />} />
        <Route path="/viewDeposit" element={<PrivateRoute element={<ViewDeposits/>} />} />
        <Route path="/convert-currency" element={<PrivateRoute element={<Convert/>} />} />
        <Route path="/full-trans" element={<PrivateRoute element={<FullTrans/>} />} />
        
      </Routes>
    </Router>
  );
}

export default App;
