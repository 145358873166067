import React from 'react'
import { useNavigate, Link } from 'react-router-dom';

const RealSide = () => {
  return (
    <div>

{/* ======= Sidebar ======= */}
<aside id="sidebar" className="sidebar">
  <ul className="sidebar-nav" id="sidebar-nav">
    
    <li className="nav-item">
      <Link className="nav-link" to="/Dashboard">
        <i className="bi bi-grid" />
        <span>Dashboard</span>
      </Link>
    </li>
    <li className="nav-item">
      <Link className="nav-link" to="/Send">
        <i className="bi bi-send" />
        <span>Transactions</span>
      </Link>
    </li>
    <li className="nav-item">
      <Link className="nav-link" to="/Trans">
        <i className="bi bi-clock-history" />
        <span>Transaction History</span>
      </Link>
    </li>
    <li className="nav-item">
      <Link className="nav-link" to="/Support">
        <i className="bi bi-question-circle" />
        <span>Support</span>
      </Link>
    </li>
    <li className="nav-item">
      <Link className="nav-link" to="/accounts">
        <i className="bi bi-person-circle" />
        <span>Client Account</span>
      </Link>
    </li>
    <li className="nav-item">
      <Link className="nav-link" to="/convert-currency">
        <i className="bi bi-arrow-left-right" />
        <span>Convert Currency</span>
      </Link>
    </li>
  
   
    
{/*   
    <li className="nav-heading">Pages</li>
    <li className="nav-item">
      <a className="nav-link collapsed" href="users-profile.html">
        <i className="bi bi-person" />
        <span>Profile</span>
      </a>
    </li> */}

    <li className="nav-item">
      <Link className="nav-link" to="/update">
        <i className="bi bi-person" />
        <span>Profile</span>
      </Link>
    </li>
    {/* <li className="nav-item">
      <a className="nav-link collapsed" href="pages-faq.html">
        <i className="bi bi-question-circle" />
        <span>F.A.Q</span>
      </a>
    </li> */}
    
   
    {/* <li className="nav-item">
      <a className="nav-link collapsed" href="pages-login.html">
        <i className="bi bi-box-arrow-in-right" />
        <span>Login</span>
      </a>
    </li> */}
    

    <li className="nav-item">
      <Link className="nav-link" to="/signout">
        <i className="bi bi-box-arrow-in-right" />
        <span>Logout</span>
      </Link>
    </li>
   
  </ul>
</aside>{/* End Sidebar*/}





    </div>
  )
}

export default RealSide