import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Container, Form, Button, Modal, Row, Col } from 'react-bootstrap';
import {  Typography, Paper } from '@mui/material';
import Cookies from 'js-cookie';


const TransactComp = () => {
    const [clientAccounts, setClientAccounts] = useState([]);
    const [beneficiaries, setBeneficiaries] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [branches, setBranches] = useState([]);
    const [formData, setFormData] = useState({
        user_id: '',
        clients_account_id: '',
        beneficiary_id: '',
        initial_transfer_amount: '',
        pay_out_branch: '',
        pay_out_methods: '',
        transaction_reason: '',
        reference_number: '',
        payment_proof: null,
        transaction_type: '',
        bank: '',
        currency_id: '' 
    });
    const [transactionDetails, setTransactionDetails] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const authToken = Cookies.get('authToken');

    useEffect(() => {
        // Fetch client accounts
        axios.get('https://api.nkolozi.com/api/Client/client-accounts', {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        })
        .then(response => {
            const accounts = response.data.data;
            if (Array.isArray(accounts)) {
                setClientAccounts(accounts);
            } else {
                console.error('Unexpected data format for client accounts:', response.data);
            }
        })
        .catch(error => {
            console.error('Error fetching client accounts:', error);
        });

        // Fetch beneficiaries
        axios.get('https://api.nkolozi.com/api/Client/beneficiaries', {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        })
        .then(response => {
            const beneficiariesData = response.data.beneficiaries;
            if (Array.isArray(beneficiariesData)) {
                setBeneficiaries(beneficiariesData);
            } else {
                console.error('Unexpected data format for beneficiaries:', response.data);
                console.log(response)
            }
        })
        .catch(error => {
            console.error('Error fetching beneficiaries:', error);
            //console.log(response)
        });

        // Fetch user ID
        axios.get('https://api.nkolozi.com/api/Client/client', {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        })
        .then(response => {
            const clientData = response.data.data;
            setFormData(prevData => ({ ...prevData, user_id: clientData.id }));
        })
        .catch(error => {
            console.error('Error fetching client data:', error);
        });

        // Fetch branches
        axios.get('https://api.nkolozi.com/api/Client/client-branchs', {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        })
        .then(response => {
            const branchData = response.data.data;
            if (Array.isArray(branchData)) {
                setBranches(branchData);
            } else {
                console.error('Unexpected data format for branches:', response.data);
            }
        })
        .catch(error => {
            console.error('Error fetching branches:', error);
        });

        axios.get('https://api.nkolozi.com/api/Client/currencies', {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        })
        .then(response => {
            const currenciesData = response.data.data;
            if (Array.isArray(currenciesData)) {
                setCurrencies(currenciesData);
            } else {
                console.error('Unexpected data format for currencies:', response.data);
            }
        })
        .catch(error => {
            console.error('Error fetching currencies:', error);
        });



    }, [authToken]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        setFormData({ ...formData, payment_proof: e.target.files[0] });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);

        const data = new FormData();
        Object.keys(formData).forEach(key => {
            data.append(key, formData[key]);
        });

        axios.post('https://api.nkolozi.com/api/Client/client-create-transaction', data, {
            headers: {
                Authorization: `Bearer ${authToken}`,
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => {
            setIsLoading(false);
    
            // Check if response and response.data are defined
            if (response && response.data) {
                const { status, transaction_id, total_send_amount, transa_usd_value } = response.data;
                console.log(transaction_id);
    
                if (status === 'confirm_transaction client') {
                    setTransactionDetails({ transaction_id, total_send_amount, transa_usd_value });
                    console.log('Current Prob:', response.data); // Corrected this line
                    console.log("Transaction Details:", { transaction_id, total_send_amount, transa_usd_value });
                    console.log("Form Data:", data);
    
                    setShowModal(true);
                } else {
                    alert('Transaction Failed!');
                    navigate('/transact');
                }
            } else {
                // Handle cases where response.data is undefined
                alert('Unexpected response format. Transaction Failed!');
                navigate('/transact');
            }
        })
        .catch(error => {
            setIsLoading(false);
            if (error.response) {
                // Server responded with a status code other than 2xx
                alert(`Failed to create transaction: ${error.response.data.message}`);
            } else if (error.request) {
                // Request was made but no response was received
                console.error('No Response Received:', error.request);
                alert('Failed to create transaction: No response from server.');
            } else {
                // Something happened in setting up the request that triggered an error
                console.error('Request Error:', error.message);
                console.log("Error Object Details:", error);
                alert(`Failed to create transaction: ${error.message}`);
            }
            console.error('Error Config:', error.config);
        });
        };

    const handleConfirm = () => {
        setIsLoading(true);
        axios.post('https://api.nkolozi.com/api/Client/confirm-transaction', {
            transaction_id: transactionDetails.transaction_id
        }, {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        })
        .then(response => {
            setIsLoading(false);
            alert('Transaction confirmed and successful!');
            navigate('/send');
        })
    
        .catch(error => {
            setIsLoading(false);
            console.error('Error confirming transaction:', error);
            alert('Failed to confirm transaction.');
        });
    };

    const handleCancel = () => {
        setIsLoading(true);
        axios.post('https://api.nkolozi.com/api/Client/cancel-transaction', {
            transaction_id: transactionDetails.transaction_id
        }, {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        })
        .then(cancel => {
            setIsLoading(false);
            alert('Transaction Cancelled');
            navigate('/Dashboard');
        })
        .catch(error => {
            setIsLoading(false);
            console.error('Error Cancelling transaction:', error);
            alert('Failed to Cancel The Transaction.');
        });
    };

    return (
        <div style={{ marginTop:'100px',marginLeft: '100px' }}>
        <Container >
            <Paper
        sx={{
          padding: '3px 10px', 
          marginBottom: '20px',
          width: 'fit-content', // Makes the paper fit the content
          backgroundColor: '#c5401f', // Set background color
          '@keyframes slide-up': {
            '0%': {
              transform: 'translateY(20px)',
              opacity: 0,
            },
            '100%': {
              transform: 'translateY(0)',
              opacity: 1,
            },
          },
          animation: 'slide-up 0.5s ease-in-out',
        }}
      >
        <Typography variant="h5" gutterBottom sx={{ color: '#fff' }}>
          Create Transaction
        </Typography>
      </Paper>

            <Form onSubmit={handleSubmit} className="p-3 border rounded bg-light" style={{ maxHeight: '150vh'}}>
                <Row>
                    <Col xs={12} md={6}>
                     

                        <Form.Group controlId="formBeneficiary" className=" form-control mb-3">
                            <Form.Label>Beneficiary *</Form.Label>
                            <Form.Control
                                as="select"
                                size="sm"
                                name="beneficiary_id"
                                value={formData.beneficiary_id}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">Select Beneficiary *</option>
                                {beneficiaries.map(beneficiary => (
                                    <option key={beneficiary.id} value={beneficiary.id}>
                                        {beneficiary.full_name}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formInitialTransferAmount" className="form-control mb-3">
                            <Form.Label>Initial Transfer Amount *</Form.Label>
                            <Form.Control
                                type="number"
                                size="sm"
                                name="initial_transfer_amount"
                                value={formData.initial_transfer_amount}
                                onChange={handleInputChange}
                                required
                                 min="1"
                            />
                        </Form.Group>

                        <Form.Group controlId="formCurrency" className="form-control mb-3">
                            <Form.Label>Currency *</Form.Label>
                            <Form.Control
                                as="select"
                                size="sm"
                                name="currency_id"
                                value={formData.currency_id}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">Select Currency</option>
                                {currencies.map(currency => (
                                    <option key={currency.id} value={currency.id}>
                                        {currency.name} ({currency.code})
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>

                    <Col xs={12} md={6}>
                        <Form.Group controlId="formPayOutBranch" className="form-control mb-3">
                            <Form.Label>Pay Out Branch *</Form.Label>
                            <Form.Control
                                as="select"
                                size="sm"
                                name="pay_out_branch"
                                value={formData.pay_out_branch}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">Select Branch</option>
                                {branches.map(branch => (
                                    <option key={branch.id} value={branch.id}>
                                        {branch.branch_name} ({branch.city}, {branch.location})
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                        <div class="form-group mb-3">
  <label for="formPayOutMethods" class="form-label">Pay Out Methods *</label>
  <select class="form-control form-control-sm" id="formPayOutMethods" name="pay_out_methods" value={formData.pay_out_methods} onChange={handleInputChange} required>
    <option value="">Select a method</option>
    <option value="cashout">Cash Pickup</option>
    <option value="withdrawal">Bank Transafer</option>
    <option value="withdrawal">Mobile Wallet</option>
   
  </select>
</div>

                        <Form.Group controlId="formTransactionReason" className="form-control mb-3">
                            <Form.Label>Transaction Reason *</Form.Label>
                            <Form.Control
                                type="text"
                                size="sm"
                                name="transaction_reason"
                                value={formData.transaction_reason}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formReferenceNumber" className="form-control mb-3">
                            <Form.Label>Reference Number</Form.Label>
                            <Form.Control
                                type="text"
                                size="sm"
                                name="reference_number"
                                value={formData.reference_number}
                                onChange={handleInputChange}
                               
                            />
                        </Form.Group>

                        <Form.Group controlId="formPaymentProof" className="form-control mb-3">
                            <Form.Label>Payment Proof</Form.Label>
                            <Form.Control
                                type="file"
                                size="sm"
                                name="payment_proof"
                                onChange={handleFileChange}
                             
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={6}>
                    <Form.Group controlId="formTransactionType" className="form-control mb-3">
    <Form.Label>Transaction Type *</Form.Label>
    <Form.Control
        as="select"
        size="sm"
        name="transaction_type"
        value={formData.transaction_type}
        onChange={handleInputChange}
        required
    >
        <option value="">Select a type</option>
        <option value="internal">Internal</option>
        <option value="transfer">Transfer</option>
    </Form.Control>
</Form.Group>
                    </Col>

                    <Col xs={12} md={6}>
                        <Form.Group controlId="formBank" className="form-control mb-3">
                            <Form.Label>Bank</Form.Label>
                            <Form.Control
                                type="text"
                                size="sm"
                                name="bank"
                                value={formData.bank}
                                onChange={handleInputChange}
                                
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Button variant="primary" type="submit" disabled={isLoading}>
                    {isLoading ? 'Processing...' : 'Submit'}
                </Button>
            </Form>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Transaction</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Transaction ID: {transactionDetails?.transaction_id}</p>
                    <p>Total Send Amount: {transactionDetails?.total_send_amount}</p>
                    <p>Transaction USD Value: {transactionDetails?.transa_usd_value}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCancel} disabled={isLoading}>
                        {isLoading ? 'Cancelling...' : 'Cancel'}
                    </Button>
                    <Button variant="primary " onClick={handleConfirm} disabled={isLoading}>
                        {isLoading ? 'Confirming...' : 'Confirm'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
        </div>
    );
};

export default TransactComp;
