import React from 'react'
import DashHeader from '../Components/DashHeader'
import DashSidebar from '../Components/DashSidebar'

import DashTrans from '../Components/DashTrans'
import RealNav from '../Components/RealNav'
import RealSide from '../Components/RealSide'


function TransHistory() {
  return (
   
<div >
<RealNav />
    <RealSide />
    <DashTrans />

</div>
    
   
  )
}

export default TransHistory
