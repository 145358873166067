import React, { useEffect, useState } from 'react'

const RealNav = () => {
  const [userInfo, setUserInfo] = useState({});


useEffect(()=>{
  const storedUserInfo = localStorage.getItem('userInfo');
  if (storedUserInfo) {
    setUserInfo(JSON.parse(storedUserInfo));
  }
  



},[]


)


  

  return (
    <div>

{/* ======= Header ======= */}
<header id="header" className="header fixed-top d-flex align-items-center"  style={{ backgroundColor: '#b03a30' }}>
  <div className="d-flex align-items-center justify-content-between">
    {/* <a href="index.html" className="logo d-flex align-items-center">
      <img src="assets/img/logo.png" alt />
      <span className="d-none d-lg-block">Nkolozi</span>
    </a> */}
    <i className="bi bi-list toggle-sidebar-btn" />
  </div>{/* End Logo */}
 
  <nav className="header-nav ms-auto">
    <ul className="d-flex align-items-center">
    
     
      <li className="nav-item dropdown">
      
        
      </li>{/* End Messages Nav */}
      <li className="nav-item dropdown pe-3">
        <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
          <img src="def.jpg" alt="Profile" className="rounded-circle" />
          <span className="d-none d-md-block dropdown-toggle ps-2" style={{color:"white"}}>{userInfo.name}  {userInfo.surname}</span>
        </a>{/* End Profile Iamge Icon */}
        
      </li>{/* End Profile Nav */}
    </ul>
  </nav>{/* End Icons Navigation */}
</header>{/* End Header */}




    </div>
  )
}

export default RealNav