import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import './UpdateProfComp.css';
import { Paper, Typography } from '@mui/material';     
import axios from 'axios';
import { Button } from 'react-bootstrap';
import Password from './Password';


function UpdateProfComp() {
  const [userId, setUserId] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isProfileExpanded, setIsProfileExpanded] = useState(false);
  const [isPhotosExpanded, setIsPhotosExpanded] = useState(false);
  const [isBanksExpanded, setIsBanksExpanded] = useState(false);

  const [formData, setFormData] = useState({
    id: '',
    city: '',
    location: '',
    street_line: '',
    house_number: '',
    phone2: '',
    bank_account: '',
  });

  const [photosData, setPhotosData] = useState({
    id: '',
    id_photo: null,
    user_photo: null,
    p_o_r_image: null,
  });

  const [bankData, setBankData] = useState({
    id: '',
    swift_code: '',
    bank_name: '',
    account_number: '',
    account_type: '',
    currency: '',
  });

  const [imageUrl, setImageUrl] = useState()
  console.log('img',imageUrl)
  //console.log('photo',photo)


  const [showPassword, setShowPassword] = useState(false);
  const togglePassword = () => {
    setShowPassword((prevState) => !prevState);
  };


  

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo && userInfo.id) {
      setUserId(userInfo.id);
      fetchUserData(userInfo.id);
    }
  }, []);

  const fetchUserData = async (id) => {
    const authToken = Cookies.get('authToken');
    try {
      const response = await fetch(`https://api.nkolozi.com/api/Client/client?id=${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        },
      });

      if (!response.ok) {
        const errorDetail = await response.text();
        setError(`Error fetching user data: ${response.status} - ${errorDetail}`);
        setSuccess('');
        return;
      }

      const result = await response.json();
      const data = result.data;
      //setPhoto(result.data.user_photo)
     // setImageUrl(`https://api.nkolozi.com/public/storage/${result.data.user_photo}`)
     // console.log('jdjddjddj',imageUrl)
      //console.log(result.data.user_photo)
      
      //console.log('Here', result.data.user_photo)

      // Populate the form data with the response
      setFormData({
        id: data.id || '',
        city: data.city || '',
        location: data.location || '',
        street_line: data.street_line || '',
        house_number: data.house_number || '',
        phone2: data.phone2 || '',
        bank_account: data.bank_account || '',
      });

      setPhotosData({
        id: data.id || '',
        id_photo: data.id_photo || null,
        user_photo: data.user_photo || null,
        p_o_r_image: data.p_o_r_image || null,
      });

      setBankData({
        id: data.id || '',
        account_number: data.bank_account || '',
        account_type: data.account_type || '',
        // Additional bank fields if needed
      });

    } catch (error) {
      console.error('Error fetching user data:', error);
      setError('Error fetching user data. Please try again later.');
      setSuccess('');
    }
  };

//   async function fetchPhoto() {
//     const authToken = Cookies.get('authToken');
// try {
//   var gotPhoto = await axios.get(' https://api.nkolozi.com/public/storage/users/profile_images/AObbf5PB2H8kHhKH611aIgYd2aW2HRn7PqCgkOz5.jpg', {
//     method: 'GET',
//     headers: {
//       'Authorization': `Bearer ${authToken}`,
//       'Content-Type': 'image/jpeg'
//     },
//     }

//   )
//   console.log('actual', gotPhoto)
  
// } catch (error) {
//  console.log(error) 
// }

//   }
//   fetchPhoto()


  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    console.log('Form data updated:', { ...formData, [name]: value });
  };

  const handlePhotosChange = (e) => {
    const { name, files } = e.target;
    setPhotosData({ ...photosData, [name]: files[0] });
    console.log('Photos data updated:', { ...photosData, [name]: files[0] });
  };

  const handleBankChange = (e) => {
    const { name, value } = e.target;
    setBankData({ ...bankData, [name]: value });
    console.log('Bank data updated:', { ...bankData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const authToken = Cookies.get('authToken');

    const requestBody = {
      id: userId,
      ...formData,
    };

    console.log('Request payload:', JSON.stringify(requestBody));

    try {
      const response = await fetch('https://api.nkolozi.com/api/Client/update-client-address', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        const errorDetail = await response.text();
        console.error('Error details:', errorDetail);
        setError(`HTTP error! status: ${response.status}, details: ${errorDetail}`);
        setSuccess('');
        throw new Error(`HTTP error! status: ${response.status}, details: ${errorDetail}`);
      }

      const result = await response.json();
      console.log('Update successful:', result);

      setSuccess('Profile update successful!');
      setError('');
    } catch (error) {
      console.error('Error updating user details:', error);
      setError('Error updating user details. Please try again later.');
      setSuccess('');
    }
  };

  const handlePhotosSubmit = async (e) => {
    e.preventDefault();
    const authToken = Cookies.get('authToken');

    const formData = new FormData();
    formData.append('id', userId);
    if (photosData.id_photo) formData.append('id_photo', photosData.id_photo);
    if (photosData.user_photo) formData.append('user_photo', photosData.user_photo);
    if (photosData.p_o_r_image) formData.append('p_o_r_image', photosData.p_o_r_image);

    try {
      const response = await fetch('https://api.nkolozi.com/api/Client/update-client-photos', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        const errorDetail = await response.text();
        console.error('Error details:', errorDetail);
        setError(`HTTP error! status: ${response.status}, details: ${errorDetail}`);
        setSuccess('');
        throw new Error(`HTTP error! status: ${response.status}, details: ${errorDetail}`);
      }

      const result = await response.json();
      console.log('Photos update successful:', result);

      setSuccess('Photos update successful!');
      setError('');
    } catch (error) {
      console.error('Error updating photos:', error);
      setError('Error updating photos. Please try again later.');
      setSuccess('');
    }
  };

  const handleBankSubmit = async (e) => {
    e.preventDefault();
    const authToken = Cookies.get('authToken');

    const requestBody = {
      id: userId,
      ...bankData,
    };

    console.log('Request payload:', JSON.stringify(requestBody));

    try {
      const response = await fetch('https://api.nkolozi.com/api/Client/add-bank-account', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        const errorDetail = await response.text();
        console.error('Error details:', errorDetail);
        setError(`HTTP error! status: ${response.status}, details: ${errorDetail}`);
        setSuccess('');
        throw new Error(`HTTP error! status: ${response.status}, details: ${errorDetail}`);
      }

      const result = await response.json();
      console.log('Bank update successful:', result);

      setSuccess('Bank update successful!');
      setError('');
    } catch (error) {
      console.error('Error updating bank details:', error);
      setError('Error updating bank details. Please try again later.');
      setSuccess('');
    }
  };

  return (
    <div style={{marginLeft:'10%'}}>
    
    <div className="mainPanelStyle" style={{ width: '100%', maxWidth: '800px'}}>
    
  



      <div  className="card mb-3 " >
        <div className="card-header" onClick={() => setIsProfileExpanded(!isProfileExpanded)}>
        
        <Paper
        sx={{
          padding: '3px 10px', 
          marginBottom: '20px',
          width: 'fit-content',
          backgroundColor: '#c5401f',
          '@keyframes slide-up': {
            '0%': {
              transform: 'translateY(20px)',
              opacity: 0,
            },
            '100%': {
              transform: 'translateY(0)',
              opacity: 1,
            },
          },
          animation: 'slide-up 0.5s ease-in-out',
        }}
      >
        <Typography variant="h5" gutterBottom >
          Update Your Profile
        </Typography>
      </Paper>
        </div>
        {isProfileExpanded && (
          <div className="card-body">
            {error && <div className="alert alert-danger" role="alert">{error}</div>}
            {success && <div className="alert alert-success" role="alert">{success}</div>}
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>City:</label>
                <input
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label>Location:</label>
                <input
                  type="text"
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label>Street Line:</label>
                <input
                  type="text"
                  name="street_line"
                  value={formData.street_line}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label>House Number:</label>
                <input
                  type="text"
                  name="house_number"
                  value={formData.house_number}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label>Phone 2:</label>
                <input
                  type="text"
                  name="phone2"
                  value={formData.phone2}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <button type="submit" className="btn btn-primary">Update Profile</button>
            </form>
          </div>
        )}
      </div>

      {/* Photos Section */}
      <div className="card mb-3">
        <div className="card-header" onClick={() => setIsPhotosExpanded(!isPhotosExpanded)}>
          Update Photos
        </div>
        {isPhotosExpanded && (
          <div className="card-body">
            <form onSubmit={handlePhotosSubmit}>
              <div className="form-group">
                <label>ID Photo:</label>
                <input
                  type="file"
                  name="id_photo"
                  onChange={handlePhotosChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label>User Photo:</label>
                <input
                  type="file"
                  name="user_photo"
                  onChange={handlePhotosChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label>Proof of Residence Image:</label>
                <input
                  type="file"
                  name="p_o_r_image"
                  onChange={handlePhotosChange}
                  className="form-control"
                />
              </div>
              <button type="submit" className="btn btn-primary">Update Photos</button>
            </form>
          </div>
        )}
      </div>

      {/* Bank Details Section */}
      <div className="card">
        <div className="card-header" onClick={() => setIsBanksExpanded(!isBanksExpanded)}>
          Add Bank Details
        </div>
        {isBanksExpanded && (
          <div className="card-body">
            <form onSubmit={handleBankSubmit}>
              <div className="form-group">
                <label>Swift Code:</label>
                <input
                  type="text"
                  name="swift_code"
                  value={bankData.swift_code}
                  onChange={handleBankChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label>Bank Name:</label>
                <input
                  type="text"
                  name="bank_name"
                  value={bankData.bank_name}
                  onChange={handleBankChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label>Account Number:</label>
                <input
                  type="text"
                  name="account_number"
                  value={bankData.account_number}
                  onChange={handleBankChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label>Account Type:</label>
                <input
                  type="text"
                  name="account_type"
                  value={bankData.account_type}
                  onChange={handleBankChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label>Currency:</label>
                <input
                  type="text"
                  name="currency"
                  value={bankData.currency}
                  onChange={handleBankChange}
                  className="form-control"
                />
              </div>
              <button type="submit" className="btn btn-primary">Add Bank Details</button>
            </form>
          </div>
        )}
      </div>
      <br/>
      <button
        className="btn-primary"
        style={{ marginTop: '50px' }}
        onClick={(event) => {
          event.preventDefault();
          togglePassword();
        }}
      >
        Update Password
      </button>
      {showPassword && <Password/>}
    </div>
    
    </div>
  );
}

export default UpdateProfComp;
