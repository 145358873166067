import React from 'react'
import DashHeader from '../Components/DashHeader'
import DashSidebar from '../Components/DashSidebar'
import AddBenefComp from '../Components/AddBenefComp'
import RealNav from '../Components/RealNav'
import RealSide from '../Components/RealSide'

function AddBeneficiary() {
  return (
    <div className="container-scroller">
     <RealNav className="container-fluid page-body-wrapper"/>
     <RealSide className="main-panel"/>
     <AddBenefComp className="content-wrapper"/>
    </div>
  )
}

export default AddBeneficiary
