import React from 'react'
import { Link } from 'react-router-dom'

export default function Header() {
  return (
    <div>
      <header id="header" className="header d-flex align-items-center fixed-top">
  <div className="container-fluid container-xl position-relative d-flex align-items-center">
    <a href="index.html" className="logo d-flex align-items-center me-auto">
      {/* Uncomment the line below if you also wish to use an image logo */}
      <img src="assets/img/LOGO1.png" alt />
      <h1 className="sitename">Nkolozi Money Transfer</h1>
    </a>
    <nav id="navmenu" className="navmenu">
      <ul>
        <li><a href="#hero" className="active">Home<br /></a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#services">Services</a></li>
        <li><a href="#team">Team</a></li>
        
       
        
        <li><a href="#contact" style={{ marginRight: 10 }}>Contact</a></li>
      </ul>
      <i className="mobile-nav-toggle d-xl-none bi bi-list" />
    </nav>
    <Link to="/Signup" className="btn bg-info" style={{ marginRight: 10 }}>
  Sign Up
</Link>
<Link to="/Login" className="btn btn-primary">
  Login
</Link>
  </div>
</header>

     
</div>
  )
}
