import React from 'react'

export default function Home() {
  return (
    <div>
      <main className="main">
  {/* Hero Section */}
  <section id="hero" className="hero section">
    <div className="container">
      <div className="row gy-4">
        <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
        <h1 data-aos="fade-up">Send &amp; Receive Money <i className="bi bi-send" style={{fontSize: 26}} /></h1>

        <br/>
          <h3 data-aos="fade-up">Unlock swift and secure money transfers with our cutting-edge solutions.</h3>
          
          <div className="d-flex flex-column flex-md-row" data-aos="fade-up" data-aos-delay={200}>
          <a href="#about" className="btn-get-started" style={{ backgroundColor: 'red', color: 'white' }}>
      Get Started <i className="bi bi-arrow-right" />
    </a>
            <a href="https://www.nkolozi.com" className="glightbox btn-watch-video d-flex align-items-center justify-content-center ms-0 ms-md-4 mt-4 mt-md-0"><i className="bi bi-play-circle" /><span>Watch Video</span></a>
          </div>
        </div>
        <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-out">
          <img src="https://media.istockphoto.com/id/1171877581/vector/financial-transaction-online-fast-money-transfer.jpg?s=612x612&w=0&k=20&c=bqp3LXXb5bRtiG1-08QrdBXE2Qaj326ICVAg385W3mg=" className="img-fluid animated" alt />
        </div>
      </div>
    </div>
  </section>{/* /Hero Section */}
  {/* About Section */}
  <section id="about" className="about section">
    <div className="container" data-aos="fade-up">
      <div className="row gx-0">
        <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay={200}>
          <div className="content">
            <h3>Who We Are</h3>
            <p>
            At Nkolozi, we are pioneers in the world of Domestic and international money transfers. With experience and a commitment to innovation, we empower individuals and businesses to seamlessly move their money across borders. Our cutting-edge technology, competitive rates, and personalized service make us the trusted choice for global financial transactions.
            </p>
            <div className="text-center text-lg-start">
              <a href="#" className="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center">
                <span>Read More</span>
                <i className="bi bi-arrow-right" />
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay={200}>
          <img src="bg.jpg" className="img-fluid" alt />
        </div>
      </div>
    </div>
  </section>{/* /About Section */}
  {/* Values Section */}
  <section id="values" className="values section">
    {/* Section Title */}
    <div className="container section-title" data-aos="fade-up">
      <h2>Our Values</h2>
      <p>What we value most<br /></p>
    </div>{/* End Section Title */}
    <div className="container">
      <div className="row gy-4">
        <div className="col-lg-4" data-aos="fade-up" data-aos-delay={100}>
          <div className="card">
            <img src="assets/img/values-1.png" className="img-fluid" alt />
            <h3>Transparency</h3>
            <p>You'll always have full visibility into your transactions, with clear pricing and no hidden fees.</p>
          </div>
        </div>{/* End Card Item */}
        <div className="col-lg-4" data-aos="fade-up" data-aos-delay={200}>
          <div className="card">
            <img src="assets/img/values-2.png" className="img-fluid" alt />
            <h3>Customer Focus</h3>
            <p>Your satisfaction is our top priority. Our dedicated team is here to provide personalized support and guidance.</p>
          </div>
        </div>{/* End Card Item */}
        <div className="col-lg-4" data-aos="fade-up" data-aos-delay={300}>
          <div className="card">
            <img src="assets/img/values-3.png" className="img-fluid" alt />
            <h3>Innovation</h3>
            <p>We are constantly exploring new technologies and solutions to streamline the money transfer experience.</p>
          </div>
        </div>{/* End Card Item */}
      </div>
    </div>
  </section>{/* /Values Section */}
  {/* Stats Section */}
  <section id="stats" className="stats section">
    <div className="container" data-aos="fade-up" data-aos-delay={100}>
      <div className="row gy-4">
        <div className="col-lg-3 col-md-6">
          <div className="stats-item d-flex align-items-center w-100 h-100">
            <i className="bi bi-emoji-smile color-blue flex-shrink-0" />
            <div>
              <span data-purecounter-start={0} data-purecounter-end={232} data-purecounter-duration={1} className="purecounter" />
              <p>Happy Clients</p>
            </div>
          </div>
        </div>{/* End Stats Item */}
        <div className="col-lg-3 col-md-6">
          <div className="stats-item d-flex align-items-center w-100 h-100">
            <i className="bi bi-send-plus flex-shrink-0" style={{color: '#ee6c20'}} />
            <div>
              <span data-purecounter-start={0} data-purecounter-end={521} data-purecounter-duration={1} className="purecounter" />
              <p>Instant Transfers</p>
            </div>
          </div>
        </div>{/* End Stats Item */}
        <div className="col-lg-3 col-md-6">
          <div className="stats-item d-flex align-items-center w-100 h-100">
            <i className="bi bi-headset color-green flex-shrink-0" style={{color: '#15be56'}} />
            <div>
              <span data-purecounter-start={0} data-purecounter-end={1463} data-purecounter-duration={1} className="purecounter" />
              <p>Hours Of Support</p>
            </div>
          </div>
        </div>{/* End Stats Item */}
        <div className="col-lg-3 col-md-6">
          <div className="stats-item d-flex align-items-center w-100 h-100">
            <i className="bi bi-people color-pink flex-shrink-0" style={{color: '#bb0852'}} />
            <div>
              <span data-purecounter-start={0} data-purecounter-end={15} data-purecounter-duration={1} className="purecounter" />
              <p>Hard Workers</p>
            </div>
          </div>
        </div>{/* End Stats Item */}
      </div>
    </div>
  </section>{/* /Stats Section */}
  {/* Features Section */}
  <section id="features" className="features section">
    {/* Section Title */}
    <div className="container section-title" data-aos="fade-up">
      <h2>Features</h2>
      <p>Our Advacedd Features<br /></p>
    </div>{/* End Section Title */}
    <div className="container">
      <div className="row gy-5">
        <div className="col-xl-6" data-aos="zoom-out" data-aos-delay={100}>
          <img src="assets/img/features.png" className="img-fluid" alt />
        </div>
        <div className="col-xl-6 d-flex">
          <div className="row align-self-center gy-4">
            <div className="col-md-6" data-aos="fade-up" data-aos-delay={200}>
              <div className="feature-box d-flex align-items-center">
                <i className="bi bi-check" />
                <h3>Robust Security</h3>
              </div>
            </div>{/* End Feature Item */}
            <div className="col-md-6" data-aos="fade-up" data-aos-delay={300}>
              <div className="feature-box d-flex align-items-center">
                <i className="bi bi-check" />
                <h3>Instant Transfers</h3>
              </div>
            </div>{/* End Feature Item */}
            <div className="col-md-6" data-aos="fade-up" data-aos-delay={400}>
              <div className="feature-box d-flex align-items-center">
                <i className="bi bi-check" />
                <h3>Multi-Currency Accounts</h3>
              </div>
            </div>{/* End Feature Item */}
            <div className="col-md-6" data-aos="fade-up" data-aos-delay={500}>
              <div className="feature-box d-flex align-items-center">
                <i className="bi bi-check" />
                <h3>Competitive Exchange Rates</h3>
              </div>
            </div>{/* End Feature Item */}
            <div className="col-md-6" data-aos="fade-up" data-aos-delay={600}>
              <div className="feature-box d-flex align-items-center">
                <i className="bi bi-check" />
                <h3>Flexible Funding Options</h3>
              </div>
            </div>{/* End Feature Item */}
            <div className="col-md-6" data-aos="fade-up" data-aos-delay={700}>
              <div className="feature-box d-flex align-items-center">
                <i className="bi bi-check" />
                <h3>Seamless Mobile Experience</h3>
              </div>
            </div>{/* End Feature Item */}
          </div>
        </div>
      </div>
    </div>
  </section>{/* /Features Section */}
  {/* Alt Features Section */}
  
  {/* Services Section */}
  <section id="services" className="services section">
    {/* Section Title */}
    <div className="container section-title" data-aos="fade-up">
      <h2>Services</h2>
      <p>Check Our Services<br /></p>
    </div>{/* End Section Title */}
    <div className="container">
      <div className="row gy-4">
        <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={100}>
          <div className="service-item item-cyan position-relative">
            <i className="bi bi-activity icon" />
            <h3>Personal Remittances</h3>
            <p>Send money to friends and family abroad quickly, securely, and at competitive rates. Our user-friendly platform makes international transfers a breeze.</p>
            <a href="#" className="read-more stretched-link"><span>Read More</span> <i className="bi bi-arrow-right" /></a>
          </div>
        </div>{/* End Service Item */}
        <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={200}>
          <div className="service-item item-orange position-relative">
            <i className="bi bi-broadcast icon" />
            <h3>Bulk Disbursements</h3>
            <p>Seamlessly distribute payments to multiple recipients, whether it's payouts, commissions, or supplier invoices. Streamline your mass payment operations.</p>
            <a href="#" className="read-more stretched-link"><span>Read More</span> <i className="bi bi-arrow-right" /></a>
          </div>
        </div>{/* End Service Item */}
        <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={300}>
          <div className="service-item item-teal position-relative">
            <i className="bi bi-easel icon" />
            <h3>Business Payments</h3>
            <p>Streamline your global operations with our business-focused money transfer solutions. From vendor payments to payroll, we've got you covered.</p>
            <a href="#" className="read-more stretched-link"><span>Read More</span> <i className="bi bi-arrow-right" /></a>
          </div>
        </div>{/* End Service Item */}
        <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={400}>
          <div className="service-item item-red position-relative">
            <i className="bi bi-bounding-box-circles icon" />
            <h3>Corporate Treasury Management</h3>
            <p>Centralize your international financial management with our comprehensive suite of corporate treasury services. Gain visibility, control, and efficiency over your global cash flows.</p>
            <a href="#" className="read-more stretched-link"><span>Read More</span> <i className="bi bi-arrow-right" /></a>
          </div>
        </div>{/* End Service Item */}
        <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={500}>
          <div className="service-item item-indigo position-relative">
            <i className="bi bi-calendar4-week icon" />
            <h3>Foreign Exchange</h3>
            <p>Enjoy access to real-time foreign exchange rates and the ability to lock in favorable rates for future transactions. Optimize your currency conversions with ease.</p>
            <a href="#" className="read-more stretched-link"><span>Read More</span> <i className="bi bi-arrow-right" /></a>
          </div>
        </div>{/* End Service Item */}
        <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={600}>
          <div className="service-item item-pink position-relative">
            <i className="bi bi-chat-square-text icon" />
            <h3>Corporate Treasury Management</h3>
            <p>Centralize your international financial management with our comprehensive suite of corporate treasury services. Gain visibility, control, and efficiency over your global cash flows.

Whether you're an individual sending money home or a multinational enterprise managing complex financial obligations, Nkolozi has the expertise and technology to meet your needs. Experience the difference with our industry-leading money transfer services.</p>
            <a href="#" className="read-more stretched-link"><span>Read More</span> <i className="bi bi-arrow-right" /></a>
          </div>
        </div>{/* End Service Item */}
      </div>
    </div>
  </section>{/* /Services Section */}
 
 

  {/* Contact Section */}
  <section id="contact" className="contact section">
    {/* Section Title */}
    <div className="container section-title" data-aos="fade-up">
      <h2>Contact</h2>
      <p>Contact Us</p>
    </div>{/* End Section Title */}
    <div className="container" data-aos="fade-up" data-aos-delay={100}>
      <div className="row gy-4">
        <div className="col-lg-6">
          <div className="row gy-4">
            {/* <div className="col-md-6">
              <div className="info-item" data-aos="fade" data-aos-delay={200}>
                <i className="bi bi-geo-alt" />
                <h3>Address</h3>
                <p>A108 Adam Street</p>
                <p>New York, NY 535022</p>
              </div>
            </div>End Info Item */}
            <div className="col-md-6">
              <div className="info-item" data-aos="fade" data-aos-delay={300}>
                <i className="bi bi-telephone" />
                <h3>Call Us</h3>
                <p>  0292882204</p>
                <p>+263 774675100</p>
                <p>+263 717792879</p>
                <p>+263 788709137</p>
                <p>+263 716871142</p>
              </div>
            </div>{/* End Info Item */}
            <div className="col-md-6">
              <div className="info-item" data-aos="fade" data-aos-delay={400}>
                <i className="bi bi-envelope" />
                <h3>Email Us</h3>
                <p>admin@nkolozi.com</p>
                <p> 0292882204</p>
              </div>
            </div>{/* End Info Item */}
            <div className="col-md-6">
              <div className="info-item" data-aos="fade" data-aos-delay={500}>
                <i className="bi bi-clock" />
                <h3>Open Hours</h3>
                <p>Monday - Friday</p>
                <p>9:00AM - 05:00PM</p>
              </div>
            </div>{/* End Info Item */}
          </div>
        </div>
        <div className="col-lg-6">
          <form action="forms/contact.php" method="post" className="php-email-form" data-aos="fade-up" data-aos-delay={200}>
            <div className="row gy-4">
              <div className="col-md-6">
                <input type="text" name="name" className="form-control" placeholder="Your Name" required />
              </div>
              <div className="col-md-6 ">
                <input type="email" className="form-control" name="email" placeholder="Your Email" required />
              </div>
              <div className="col-md-12">
                <input type="text" className="form-control" name="subject" placeholder="Subject" required />
              </div>
              <div className="col-md-12">
                <textarea className="form-control" name="message" rows={6} placeholder="Message" required defaultValue={""} />
              </div>
              <div className="col-md-12 text-center">
                <div className="loading">Loading</div>
                <div className="error-message" />
                <div className="sent-message">Your message has been sent. Thank you!</div>
                <button type="submit">Send Message</button>
              </div>
            </div>
          </form>
        </div>{/* End Contact Form */}
      </div>
    </div>
  </section>{/* /Contact Section */}
  
</main>
</div>
   
  )
}
