import React, { useState } from 'react';

const containerStyle = {
  maxWidth: '600px',
  margin: '0 auto',
  padding: '20px',
  border: '1px solid #ccc',
  borderRadius: '8px',
  backgroundColor: '#f9f9f9',
};

const headingStyle = {
  textAlign: 'center',
  marginBottom: '20px',
};

const formGroupStyle = {
  marginBottom: '15px',
};

const labelStyle = {
  display: 'block',
  marginBottom: '5px',
};

const inputStyle = {
  width: '100%',
  padding: '10px',
  border: '1px solid #ccc',
  borderRadius: '4px',
};

const buttonStyle = {
  width: '100%',
  padding: '10px',
  border: 'none',
  borderRadius: '4px',
  backgroundColor: '#4CAF50',
  color: 'white',
  cursor: 'pointer',
};

const errorMessageStyle = {
  color: 'red',
  textAlign: 'center',
};

const successMessageStyle = {
  color: 'green',
  textAlign: 'center',
};

function FinishSignupComp() {
  const [formData, setFormData] = useState({
    city: '',
    location: '',
    street_line: '',
    house_number: '',
    phone2: '',
    bank_account: '',
    user_photo: null,
    p_o_r_image: null,
  });

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];
    setFormData({
      ...formData,
      [name]: file,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    // First, submit the main form data
    const data = {
      city: formData.city,
      location: formData.location,
      street_line: formData.street_line,
      house_number: formData.house_number,
      phone2: formData.phone2,
      bank_account: formData.bank_account,
    };

    try {
      const mainResponse = await fetch('https://api.nkolozi.com/api/Client/update-client-address', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!mainResponse.ok) {
        throw new Error(`Main request failed: ${mainResponse.statusText}`);
      }

      // Then, submit the user photo if it exists
      if (formData.user_photo) {
        const userPhotoData = new FormData();
        userPhotoData.append('user_photo', formData.user_photo);

        const userPhotoResponse = await fetch('https://api.nkolozi.com/api/Client/update-client-photos', {
          method: 'POST',
          body: userPhotoData,
        });

        if (!userPhotoResponse.ok) {
          throw new Error(`User photo request failed: ${userPhotoResponse.statusText}`);
        }
      }

      // Finally, submit the proof of residence image
      const porImageData = new FormData();
      porImageData.append('p_o_r_image', formData.p_o_r_image);

      const porImageResponse = await fetch('YOUR_POR_IMAGE_API_ENDPOINT_HERE', {
        method: 'POST',
        body: porImageData,
      });

      if (!porImageResponse.ok) {
        throw new Error(`Proof of residence image request failed: ${porImageResponse.statusText}`);
      }

      setSuccess('Account signup finished successfully!');
    } catch (err) {
      setError('Something went wrong: ' + err.message);
    }
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Welcome! Finish the Account Signup</h1>
      {error && <p style={errorMessageStyle}>{error}</p>}
      {success && <p style={successMessageStyle}>{success}</p>}
      <form onSubmit={handleSubmit}>
        <div style={formGroupStyle}>
          <label style={labelStyle}>City:</label>
          <input type="text" name="city" value={formData.city} onChange={handleChange} required style={inputStyle} />
        </div>
        <div style={formGroupStyle}>
          <label style={labelStyle}>Location:</label>
          <input type="text" name="location" value={formData.location} onChange={handleChange} required style={inputStyle} />
        </div>
        <div style={formGroupStyle}>
          <label style={labelStyle}>Street Line:</label>
          <input type="text" name="street_line" value={formData.street_line} onChange={handleChange} required style={inputStyle} />
        </div>
        <div style={formGroupStyle}>
          <label style={labelStyle}>House Number:</label>
          <input type="text" name="house_number" value={formData.house_number} onChange={handleChange} required style={inputStyle} />
        </div>
        <div style={formGroupStyle}>
          <label style={labelStyle}>Phone (Optional):</label>
          <input type="text" name="phone2" value={formData.phone2} onChange={handleChange} style={inputStyle} />
        </div>
        <div style={formGroupStyle}>
          <label style={labelStyle}>Bank Account (Optional):</label>
          <input type="text" name="bank_account" value={formData.bank_account} onChange={handleChange} style={inputStyle} />
        </div>
        <div style={formGroupStyle}>
          <label style={labelStyle}>User Photo (Optional):</label>
          <input type="file" name="user_photo" onChange={handleFileChange} accept="image/jpg, image/jpeg, image/png" style={inputStyle} />
        </div>
        <div style={formGroupStyle}>
          <label style={labelStyle}>Proof of Residence Image:</label>
          <input type="file" name="p_o_r_image" onChange={handleFileChange} accept="image/jpg, image/jpeg, image/png" required style={inputStyle} />
        </div>
        <button type="submit" style={buttonStyle}>Finish Signup</button>
      </form>
    </div>
  );
}

export default FinishSignupComp;
