import React from 'react'
import DashHeader from '../Components/DashHeader'
import DashSidebar from '../Components/DashSidebar'
import TransactComp from '../Components/TransactComp'
import RealNav from '../Components/RealNav'
import RealSide from '../Components/RealSide'

function Transact() {
  return (
    <div className="container-scroller">
    <RealNav className="container-fluid page-body-wrapper"/>
    <RealSide className="main-panel"/>
    <TransactComp className="content-wrapper"/>
    </div>
  )
}

export default Transact
