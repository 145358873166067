import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Circles } from 'react-loader-spinner'; 
import Cookies from 'js-cookie';

function Signout() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // State to manage loading

  useEffect(() => {
    // Function to handle the signout process
    const handleSignout = async () => {
      try {
        const authToken = Cookies.get('authToken'); // Get the token from the cookie

        const response = await fetch('https://api.nkolozi.com/api/Client/client', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json'
          }
        });

        // Check if the response is in JSON format
        const contentType = response.headers.get('content-type');
        let result;
        if (contentType && contentType.indexOf('application/json') !== -1) {
          result = await response.json();
        } else {
          result = { status: 'unknown', message: 'Non-JSON response' };
        }

        if (response.ok) {
          if (result.status === 'Request was successful' || response.status === 200) {
            Cookies.remove('authToken'); // Remove the token cookie
            localStorage.removeItem('userInfo')
            navigate('/Login'); // Redirect to the login page
          } else {
            console.error(result.message);
            navigate('/Login'); // Redirect to login even if there's an error
          }
        } else {
          console.error('Signout failed:', result.message);
          navigate('/Login'); // Redirect to login even if there's an error
        }
      } catch (error) {
        console.error('Error during signout:', error);
        navigate('/Login'); // Redirect to login even if there's an error
      } finally {
        setLoading(false); // Set loading to false after the process
      }
    };

    handleSignout();
  }, [navigate]);

  return (
    <div style={styles.container}>
      {loading ? (
        <div style={styles.loader}>
          <Circles color="#00BFFF" height={80} width={80} />
          <p>Signing out, please wait...</p>
        </div>
      ) : null}
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f0f0f0'
  },
  loader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
};

export default Signout;
