import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

function UserDash() {
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currencies, setCurrencies] = useState({});

  // Get user info from localStorage
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const userId = userInfo ? userInfo.id : '';

  // Get auth token from cookies
  const authToken = Cookies.get('authToken');

  useEffect(() => {
    async function fetchCurrencies() {
      try {
        const currencyResponse = await axios.get('https://api.nkolozi.com/api/Client/currencies', {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        });

        const currencyMap = {};
        currencyResponse.data.data.forEach(currency => {
          currencyMap[currency.id] = currency.name;
        });

        setCurrencies(currencyMap);
      } catch (error) {
        console.error('Error fetching currencies:', error);
      }
    }

    async function fetchClientAccounts() {
      try {
        const response = await axios.get(`https://api.nkolozi.com/api/Client/client-accounts/${userId}`, {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        });

        if (response.data && Array.isArray(response.data.client_accounts)) {
          setAccounts(response.data.client_accounts);
        } else {
          console.error('Unexpected response format:', response.data);
          setAccounts([]);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching accounts:', error);
        setLoading(false);
      }
    }

    fetchCurrencies();
    fetchClientAccounts();
  }, [authToken, userId]);

  return (
    <div >
    <div className="card border-0 shadow-lg rounded-lg">
      <div className="card-header text-white text-center py-4" style={{ background: 'linear-gradient(135deg, #003366, #3366cc)', position: 'relative' }}>
        <div
          className="bg-primary text-white d-flex align-items-center justify-content-center shadow-lg"
          style={{
            width: '80px',
            height: '80px',
            borderRadius: '50%',
            background: 'radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(0,0,0,0.15) 100%)',
            boxShadow: '0 10px 20px rgba(0,0,0,0.2)',
            position: 'absolute',
            top: '20px',
            left: '10px',
            fontSize: '0.8rem'
          }}
        >
          <div className="text-center">
            <h6 className="mb-1">Welcome</h6>
          </div>
        </div>
        <h4 className="mb-0" style={{ marginLeft: '140px' }}>Client Account Balances</h4>
        <div
          className="bg-danger text-white d-flex align-items-center justify-content-center shadow-lg"
          style={{
            width: '80px',
            height: '80px',
            borderRadius: '50%',
            background: 'radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(0,0,0,0.15) 100%)',
            boxShadow: '0 10px 20px rgba(0,0,0,0.2)',
            position: 'absolute',
            top: '20px',
            right: '10px',
            fontSize: '0.8rem'
          }}
        >
          <div className="text-center">
            <h6 className="mb-1">User</h6>
          </div>
        </div>
      </div>
      <div className="card-body p-4">
        {loading ? (
          <p className="text-center text-muted">Loading...</p>
        ) : accounts.length > 0 ? (
          <div className="row">
            {accounts.map((account, index) => (
              <div className="col-lg-3 col-md-4 mb-3" key={index}>
                <div className="card border-light rounded shadow-sm">
                  <div className="card-body d-flex flex-column justify-content-between">
                    <div>
                      <h5 className="card-title mb-2">{currencies[account.currency_id] || account.currency_id}</h5>
                      <p className="card-text mb-0">Current Balance:</p>
                    </div>
                    <h3 className="font-weight-bold text-primary mb-0" style={{ fontSize: '12px' }}>${account.current_balance.toFixed(2)}</h3>
                  </div>
                  <div className="card-footer bg-light text-muted text-center">
                    Updated at: {new Date(account.updated_at).toLocaleDateString()}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-center text-muted">No client accounts found.</p>
        )}
      </div>
    </div>
    </div>
  );
}

export default UserDash;