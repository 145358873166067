import React from 'react'
import Header from '../Components/Header'
import Home from '../Components/Home'
import Footer from '../Components/Footer'

export default function Main() {
  return (
    <div>
      <div className="App">
 
 <Header/>
   <Home/>
   <Footer/>
    </div>
    </div>
  )
}
