import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import './DashSupport.css';

function DashSupport() {
  const [formData, setFormData] = useState({
    subject: '',
    description: '',
    category: 'Transaction',
    priority: 'Low',
    status: 'Pending' // Assuming status should be set by default
  });
  const [status, setStatus] = useState(''); // Default status
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is authenticated
    const authToken = Cookies.get('authToken');
    if (!authToken) {
      navigate('/login'); // Redirect to login if not authenticated
      return;
    }

    // Fetch user data from localStorage
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo && userInfo.id) {
      setFormData((prevData) => ({ ...prevData, user_id: userInfo.id }));
    } else {
      navigate('/login'); // Redirect to login if user info is not available
    }
  }, [navigate]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('Sending...');
    const authToken = Cookies.get('authToken');

    try {
      const response = await fetch('https://api.nkolozi.com/api/Client/client-request-support', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        const errorDetail = await response.text();
        console.error('Error details:', errorDetail);
        setStatus(`Error! status: ${response.status}, details: ${errorDetail}`);
        throw new Error(`HTTP error! status: ${response.status}, details: ${errorDetail}`);
      }

      const result = await response.json();
      console.log(result);
      setStatus('Sent');
    } catch (error) {
      console.error('Error:', error);
      setStatus('Error Sending');
    }
  };

  return (
    <div className="mainPanelStyle" style={{marginLeft:"38%"}}>
      <h1 className="headerStyle">Support Center</h1>
      <div className="supportContainerStyle">
        <div className="supportFormStyle">
          <h2 className="subHeaderStyle">Contact Us</h2>
          <form className="formStyle" onSubmit={handleSubmit}>
            <label className="labelStyle form-control">
              Subject:
              <input
                type="text"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                className="inputStyle"
                required
              />
            </label>
            <label className="labelStyle form-control">
              Description:
              <textarea
                name="description"
                value={formData.description}
                onChange={handleChange}
                rows="5"
                className="inputStyle"
                required
              ></textarea>
            </label>
            <label className="labelStyle form-control">
              Category:
              <select
                name="category"
                value={formData.category}
                onChange={handleChange}
                className="inputStyle"
                required
              >
                <option value="Transaction">Transaction</option>
                <option value="Account Blocked">Account Blocked</option>
                <option value="General">General</option>
                <option value="Other">Other</option>
              </select>
            </label>
            <label className="labelStyle form-control">
              Priority:
              <select
                name="priority"
                value={formData.priority}
                onChange={handleChange}
                className="inputStyle"
                required
              >
                <option value="High">High</option>
                <option value="Medium">Medium</option>
                <option value="Low">Low</option>
              </select>
            </label>
            <button type="submit" className="buttonStyle">Submit</button>
            <div className="statusMessage">Status: {status}</div>
          </form>
        </div>
        <div className="faqStyle">
          <h2 className="subHeaderStyle">Frequently Asked Questions</h2>
          <div className="faqItemStyle">
            <h3 className="faqItemHeaderStyle">How can I reset my password?</h3>
            <p>You can reset your password by clicking on the "Forgot Password" link on the login page.</p>
          </div>
          <div className="faqItemStyle">
            <h3 className="faqItemHeaderStyle">How do I contact support?</h3>
            <p>You can contact support by filling out the form on this page or by emailing support@example.com.</p>
          </div>
          <div className="faqItemStyle">
            <h3 className="faqItemHeaderStyle">What is the response time for support queries?</h3>
            <p>Our typical response time is within 24 hours on business days.</p>
          </div>
          {/* Add more FAQs as needed */}
        </div>
      </div>
    </div>
  );
}

export default DashSupport;
