import React, { useState } from 'react';
import './AddBenefComp.css';

function AddBenefComp() {
  const [formData, setFormData] = useState({
    user_id: '',
    full_name: '',
    national_id: '',
    relationship: '',
    delivery_methods: '',
    pay_out_branch: '',
    country: '',
    address: '',
    phone: '',
    reason_for_remittance: '',
  });

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const dataToSend = { ...formData };

    try {
      const response = await fetch('https://api.nkolozi.com/api/Client/add-beneficiary-account', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      });

      if (!response.ok) {
        const text = await response.text();
        throw new Error(text);
      }

      const data = await response.json();
      if (data.success) {
        setSuccess(true);
        setError(null);
        setFormData({
          user_id: '',
          full_name: '',
          national_id: '',
          relationship: '',
          delivery_methods: '',
          pay_out_branch: '',
          country: '',
          address: '',
          phone: '',
          reason_for_remittance: '',
        });
      } else {
        setError(data.message || 'Failed to add beneficiary.');
        setSuccess(false);
      }
    } catch (error) {
      setError(error.message);
      setSuccess(false);
    }
  };

  return (
    <div className="add-benef-comp">
      <h1>Add Beneficiary1</h1>
      {success && <div className="alert success">Beneficiary added successfully!</div>}
      {error && <div className="alert error">{error}</div>}
      <form onSubmit={handleSubmit}>
        <label>
          User ID:
          <input type="text" name="user_id" value={formData.user_id} onChange={handleChange} required />
        </label>
        <label>
          Full Name:
          <input type="text" name="full_name" value={formData.full_name} onChange={handleChange} required />
        </label>
        <label>
          National ID:
          <input type="text" name="national_id" value={formData.national_id} onChange={handleChange} required />
        </label>
        <label>
          Relationship:
          <select name="relationship" value={formData.relationship} onChange={handleChange} required>
            <option value="" disabled>Select Relationship</option>
            <option value="1">Friend</option>
            <option value="2">Relative</option>
            <option value="3">Parent</option>
            <option value="4">Sibling</option>
            <option value="5">Other</option>
          </select>
        </label>
        <label>
          Delivery Methods:
          <select name="delivery_methods" value={formData.delivery_methods} onChange={handleChange} required>
            <option value="" disabled>Select Delivery Method</option>
            <option value="Bank Transfer">Bank Transfer</option>
            <option value="Cash Pickup">Cash Pickup</option>
            <option value="Mobile Money">Mobile Money</option>
          </select>
        </label>
        <label>
          Pay Out Branch:
          <select name="pay_out_branch" value={formData.pay_out_branch} onChange={handleChange} required>
            <option value="" disabled>Select Payout Branch</option>
            <option value="Branch 1">Branch 1</option>
            <option value="Branch 2">Branch 2</option>
            <option value="Branch 3">Branch 3</option>
          </select>
        </label>
        <label>
          Country:
          <input type="text" name="country" value={formData.country} onChange={handleChange} required />
        </label>
        <label>
          Address:
          <input type="text" name="address" value={formData.address} onChange={handleChange} required />
        </label>
        <label>
          Phone:
          <input type="text" name="phone" value={formData.phone} onChange={handleChange} required />
        </label>
        <label>
          Reason for Remittance:
          <input type="text" name="reason_for_remittance" value={formData.reason_for_remittance} onChange={handleChange} />
        </label>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default AddBenefComp;
