import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import Cookies from 'js-cookie';
import 'bootstrap/dist/css/bootstrap.min.css';

function ViewDep() {
  const [deposits, setDeposits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDeposits = async () => {
      try {
        const authToken = Cookies.get('authToken'); // Retrieve the auth token from the cookie

        const response = await axios.get('https://api.nkolozi.com/api/Client/client-deposits', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
          }
        });

        setDeposits(response.data.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchDeposits();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div style={{ marginLeft: '3%', marginTop: '7%' }}>
      <h1>Deposits</h1>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>User ID</th>
            <th>Currency</th>
            <th>Amount</th>
            <th>Type</th>
            <th>Status</th>
            <th>Reference Number</th>
            <th>Payment Proof</th>
            <th>Message</th>
            <th>Created At</th>
            <th>Updated At</th>
          </tr>
        </thead>
        <tbody>
          {deposits.map((deposit) => (
            <tr key={deposit.id}>
              <td>{deposit.id}</td>
              <td>{deposit.users_id}</td>
              <td>{deposit.currency_id}</td>
              <td>{deposit.amount}</td>
              <td>{deposit.type}</td>
              <td>{deposit.status}</td>
              <td>{deposit.reference_number}</td>
              <td>
                <a href={`https://api.nkolozi.com/${deposit.payment_proof}`} target="_blank" rel="noopener noreferrer">
                  View
                </a>
              </td>
              <td>{deposit.message}</td>
              <td>{new Date(deposit.created_at).toLocaleString()}</td>
              <td>{new Date(deposit.updated_at).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default ViewDep;
