import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';

export default function Sidebar() {

  const [userInfo, setUserInfo] = useState({});
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    const storedUserInfo = localStorage.getItem('userInfo');
    if (storedUserInfo) {
      setUserInfo(JSON.parse(storedUserInfo));
    }

    const fetchBalance = async () => {
      try {
        const authToken = Cookies.get('authToken');
        const response = await fetch('https://api.nkolozi.com/api/Client/client-accounts', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
          },
        });

        if (response.ok) {
          const result = await response.json();
          console.log('API response:', result);

          const account = result.data.find(account => account.user_id );
          if (account) {
            setBalance(account.current_balance);
          } else {
            console.log('No account found for the given user_id.');
          }
        } else {
          console.log('Failed to retrieve balance:', response.statusText);
        }
      } catch (error) {
        console.log('An error occurred:', error.message);
      }
    };

    if (Cookies.get('authToken')) {
      fetchBalance();
    }
  

  }, []);


  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar " style={{ marginLeft: '1px', background: 'linear-gradient(135deg, #003366, #3366cc)'}}>
      <div className="text-center sidebar-brand-wrapper d-flex align-items-center">
        <a className="sidebar-brand brand-logo" href="index.html"><img src="assets/img/LOGO1.png" alt="logo" /></a>
        <a className="sidebar-brand brand-logo-mini pl-4 pt-3" href="index.html"><img src="assets/img/LOGO1.png" alt="logo" /></a>
      </div>
      <ul className="nav">
        <li className="nav-item nav-profile">
          <a href="#" className="nav-link">
            <div className="nav-profile-image">
            <img src="DashAssets/assets/images/faces/def.jpg" alt="profile" />
              <span style={{ color: 'white' }} className="login-status online" />
            </div>
            <div className="nav-profile-text d-flex flex-column pr-3">
              <span className="font-weight-medium mb-2" style={{ color: 'white' }}>{userInfo.name} {userInfo.surname}</span>
              
            </div>

          </a>
        </li>
        <li className="nav-item">
          <Link to="/Dashboard" className="nav-link">
            <i style={{ color: 'white' }} className="mdi mdi-home menu-icon" />
            <span className="menu-title" style={{ color: 'white' }}>Dashboard</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/Send" className="nav-link">
            <i style={{ color: 'white' }} className="mdi mdi-send menu-icon" />
            <span className="menu-title" style={{ color: 'white' }}>Send Money</span>
          </Link>
        </li>

        {/* <li className="nav-item">
          <Link to="/viewDeposit" className="nav-link">
            <i className="mdi mdi-bank menu-icon" />
            <span className="menu-title">Deposits</span>
          </Link>
        </li> */}

        {/* <li className="nav-item">
          <Link to="/Send" className="nav-link">
            <i className="mdi mdi-arrow-down-bold-circle menu-icon" />
            <span className="menu-title">Withdrawals</span>
          </Link>
        </li> */}

        <li className="nav-item">
          <Link to="/Trans" className="nav-link">
            <i style={{ color: 'white' }} className="mdi mdi-history menu-icon" />
            <span className="menu-title" style={{ color: 'white' }}>Transaction History</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/Support" className="nav-link">
            <i style={{ color: 'white' }} className="mdi mdi-face-agent menu-icon" />
            <span className="menu-title" style={{ color: 'white' }}>Support</span>
          </Link>
        </li>
        <li className="nav-item">
  <Link to="/update" className="nav-link">
    <i style={{ color: 'white' }} className="mdi mdi-account menu-icon" /> 
    <span className="menu-title" style={{ color: 'white' }}>Update Profile</span>
  </Link>
</li>

<li className="nav-item">
  <Link to="/accounts" className="nav-link">
    <i style={{ color: 'white' }} className="mdi mdi-currency-usd" /> 
    <span className="menu-title" style={{ color: 'white' }}>Client Account</span>
  </Link>
</li>

<li className="nav-item">
  <Link to="/convert-currency" className="nav-link">
    <i style={{ color: 'white' }} className="mdi mdi-swap-horizontal menu-icon" />
    <span  className="menu-title" style={{ color: 'white' }}>Convert Currency</span>
  </Link>
</li>

        <li className="nav-item">
          <Link to="/Notifications" className="nav-link">
            <i style={{ color: 'white' }} className="mdi mdi-bell menu-icon" />
            <span className="menu-title" style={{ color: 'white' }}>Notifications</span>
          </Link>
        </li>
        <li className="nav-item">
          <span className="nav-link" href="#">
            <span className="menu-title">.</span>
          </span>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#">
          
           
          </a>
        </li>
        <li className="nav-item sidebar-actions">
      <Link to="/signout" className="nav-link">
        <div className="mt-4">
          <ul className="mt-4 pl-0">
            <li style={{ color: 'white' }}>Sign Out</li>
          </ul>
        </div>
      </Link>
    </li>
      </ul>
    </nav>
  );
}
