import React from 'react'
import DashSidebar from '../Components/DashSidebar'
import UpdateProfComp from '../Components/UpdateProfComp'
import DashHeader from '../Components/DashHeader'
import RealNav from '../Components/RealNav'
import RealSide from '../Components/RealSide'

function UpdateProf() {
  return (
    <div className="container-scroller">
       <RealNav className="container-fluid page-body-wrapper"/>
     <RealSide className="main-panel"/>
     <UpdateProfComp className="content-wrapper"/>
    </div>
  )
}

export default UpdateProf
