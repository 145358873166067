import React from 'react'
import DepositComp from '../Components/DepositComp'

function Deposit() {
  return (
    <DepositComp/>
  )
}

export default Deposit
