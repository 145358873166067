import React from 'react'
import DashHeader from '../Components/DashHeader'
import DashSidebar from '../Components/DashSidebar'
import DashSupport from '../Components/DashSupport'
import RealNav from '../Components/RealNav'
import RealSide from '../Components/RealSide'

function Support() {
  return (
    <div className="container-scroller">
     <RealNav className="container-fluid page-body-wrapper"/>
     <RealSide className="main-panel"/>
     <DashSupport className="content-wrapper"/>
    </div>
  )
}

export default Support
