// DashBody.js
import React from 'react';
import ReceiverInfo from './ReceiverInfo';


function DashBody() {
  return (
    <div className="dash-body">
      <div >
        <ReceiverInfo className="main-panel"/>
      </div>
    </div>
  );
}

export default DashBody;
