import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import Cookies from 'js-cookie';

function DepositComp() {
  const [formData, setFormData] = useState({
    user_id: '',
    clients_account_id: '',
    deposited_amount: '',
    type: '',
    reference_number: '',
    payment_proof: null,
  });
  const [accounts, setAccounts] = useState([]);
  const [authToken, setAuthToken] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      setFormData((prev) => ({ ...prev, user_id: userInfo ? userInfo.id : '' }));
    };

    const fetchAccounts = async () => {
      try {
        const response = await axios.get('https://api.nkolozi.com/api/Client/client-accounts', {
          headers: {
            'Authorization': `Bearer ${Cookies.get('authToken')}`
          }
        });
        if (response.data.status === "Request was successfull") {
          setAccounts(response.data.data);
        } else {
          setError(response.data.message);
        }
      } catch (error) {
        console.error('Error fetching accounts:', error);
        setError('Error fetching accounts. Please try again.');
      }
    };

    fetchUserData();
    fetchAccounts();
    setAuthToken(Cookies.get('authToken'));
  }, []);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'payment_proof') {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    const data = new FormData();
    for (const key in formData) {
      data.append(key, formData[key]);
    }

    try {
      const response = await axios.post('https://api.nkolozi.com/api/Client/client-create-deposit', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${authToken}`,
        },
      });

      if (response.data.status === "Request was successfull") {
        setSuccess('Deposit was successful.');
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      console.error(error);
      setError('An error occurred while submitting the form. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-5">
      <div className="card" style={{ maxWidth: '500px', margin: '0 auto' }}>
        <div className="card-body">
          <h5 className="card-title">Deposit Cash</h5>
          
          {loading && (
            <div className="d-flex justify-content-center mb-3">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          
          {success && (
            <div className="alert alert-success" role="alert">
              {success}
            </div>
          )}
          
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="clients_account_id">Client's Account ID</label>
              <select
                className="form-control"
                id="clients_account_id"
                name="clients_account_id"
                value={formData.clients_account_id}
                onChange={handleChange}
                required
              >
                <option value="">Select Account</option>
                {accounts.map(account => (
                  <option key={account.id} value={account.id}>
                    Account ID: {account.id} - Balance: {account.available_balance}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="deposited_amount">Deposited Amount</label>
              <input
                type="number"
                className="form-control"
                id="deposited_amount"
                name="deposited_amount"
                value={formData.deposited_amount}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="type">Type</label>
              <select
                className="form-control"
                id="type"
                name="type"
                value={formData.type}
                onChange={handleChange}
                required
              >
                <option value="">Select Type</option>
                <option value="bank_transfer">Bank Transfer</option>
                <option value="mobile_wallets">Mobile Wallets</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="reference_number">Reference Number</label>
              <input
                type="text"
                className="form-control"
                id="reference_number"
                name="reference_number"
                value={formData.reference_number}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="payment_proof">Payment Proof</label>
              <input
                type="file"
                className="form-control"
                id="payment_proof"
                name="payment_proof"
                accept="image/*"
                onChange={handleChange}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary mt-3">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default DepositComp;
