import React, { useState } from 'react';

function DashHeader() {
  const [isMinimized, setIsMinimized] = useState(false);
  
  const handleToggle = () => {
    setIsMinimized(!isMinimized);
    document.body.classList.toggle('sidebar-icon-only');
  };

  return (
    <div>
      <nav className="navbar col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row justify-content-end"  >
        <div className="navbar-menu-wrapper d-flex align-items-stretch justify-content-between" style={{ backgroundColor: '#b03a30' }}>
          <a className="navbar-brand brand-logo-mini align-self-center d-lg-none" href="index.html">
            <img src="assets/images/logo-mini.svg" alt="logo" />
          </a>
          <button 
            className="navbar-toggler navbar-toggler align-self-center mr-2" 
            type="button" 
            onClick={handleToggle}
          >
            <i className="mdi mdi-menu" />
          </button>
         
    
          <button 
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" 
            type="button" 
            onClick={handleToggle}
          >
            <span className="mdi mdi-menu" />
          </button>
        </div>
      </nav>
    </div>
  );
}

export default DashHeader;
