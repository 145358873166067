import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';
import { Paper, Typography } from '@mui/material';     

function DashTrans() {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const token = Cookies.get('authToken'); // Retrieve the token from cookies

  useEffect(() => {
    const fetchTransactions = async () => {
      if (!token) {
        console.error('No authentication token found.');
        setError('No authentication token found.');
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get('https://api.nkolozi.com/api/Client/client-transactions', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log('Raw API response:', response.data); // Log the raw response to check its format

        if (response.data && response.data.data) {
          setTransactions(response.data.data);
        } else {
          console.error('Unexpected data format:', response.data);
          setError('Unexpected data format.');
        }
      } catch (error) {
        console.error('Error fetching transactions:', error);
        setError('Error fetching transactions.');
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [token]);

  const handleRowClick = (transaction) => {
    setSelectedTransaction(transaction);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedTransaction(null);
  };

  if (loading) {
    return (
    
      <div  className="container mt-5" style={{marginLeft:"5%"}} >
       
        <Paper
        sx={{
          padding: '3px 10px', 
          marginBottom: '20px',
          width: 'fit-content', // Makes the paper fit the content
          backgroundColor: '#c5401f', // Set background color
          '@keyframes slide-up': {
            '0%': {
              transform: 'translateY(20px)',
              opacity: 0,
            },
            '100%': {
              transform: 'translateY(0)',
              opacity: 1,
            },
          },
          animation: 'slide-up 0.5s ease-in-out',
        }}
      >
        <Typography variant="h5" gutterBottom sx={{ color: '#fff' }}>
          Transaction History
        </Typography>
      </Paper>
        <p>Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mt-5">

<Paper
        sx={{
          padding: '3px 10px', 
          marginBottom: '20px',
          width: 'fit-content', // Makes the paper fit the content
          backgroundColor: '#c5401f', // Set background color
          '@keyframes slide-up': {
            '0%': {
              transform: 'translateY(20px)',
              opacity: 0,
            },
            '100%': {
              transform: 'translateY(0)',
              opacity: 1,
            },
          },
          animation: 'slide-up 0.5s ease-in-out',
        }}
      >
        <Typography variant="h5" gutterBottom sx={{ color: '#fff' }}>
          Transaction History
        </Typography>
      </Paper>

        <p className="text-danger">{error}</p>
      </div>
    );
  }

  if (transactions.length === 0) {
    return (
      <div className="container mt-5">
      <Paper
        sx={{
          padding: '3px 10px', 
          marginBottom: '20px',
          width: 'fit-content', // Makes the paper fit the content
          backgroundColor: '#c5401f', // Set background color
          '@keyframes slide-up': {
            '0%': {
              transform: 'translateY(20px)',
              opacity: 0,
            },
            '100%': {
              transform: 'translateY(0)',
              opacity: 1,
            },
          },
          animation: 'slide-up 0.5s ease-in-out',
        }}
      >
        <Typography variant="h5" gutterBottom sx={{ color: '#fff' }}>
          Transaction History
        </Typography>
      </Paper>
        <p>No transaction data available.</p>
      </div>
    );
  }

  console.log('Transactions data to be rendered:', transactions);

  return (
    <div className="container mt-5">
     <Paper
        sx={{
          padding: '3px 10px', 
          marginBottom: '20px',
          width: 'fit-content', // Makes the paper fit the content
          backgroundColor: '#c5401f', // Set background color
          '@keyframes slide-up': {
            '0%': {
              transform: 'translateY(20px)',
              opacity: 0,
            },
            '100%': {
              transform: 'translateY(0)',
              opacity: 1,
            },
          },
          animation: 'slide-up 0.5s ease-in-out',
        }}
      >
        <Typography variant="h5" gutterBottom sx={{ color: '#fff' }}>
          Transaction History
        </Typography>
      </Paper>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Transaction ID</th>
            <th>Initial Transfer Amount</th>
            <th>Transaction Fee</th>
            <th>Total Send Amount</th>
            <th>Status</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction) => (
            <tr key={transaction.id} onClick={() => handleRowClick(transaction)}>
              <td>{transaction.transaction_id ?? 'N/A'}</td>
              <td>{transaction.initial_transfer_amount ?? 'N/A'}</td>
              <td>{transaction.transaction_fee ?? 'N/A'}</td>
              <td>{transaction.total_send_amount ?? 'N/A'}</td>
              <td>{transaction.status ?? 'N/A'}</td>
              <td>{transaction.created_at ? new Date(transaction.created_at).toLocaleString() : 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Transaction Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedTransaction && (
            <div>
              <p><strong>ID:</strong> {selectedTransaction.id}</p>
              <p><strong>Transaction ID:</strong> {selectedTransaction.transaction_id}</p>
              <p><strong>User ID:</strong> {selectedTransaction.users_id}</p>
              <p><strong>Client Account ID:</strong> {selectedTransaction.clients_account_id}</p>
              <p><strong>Beneficiary ID:</strong> {selectedTransaction.beneficiary_id}</p>
              <p><strong>Initial Transfer Amount:</strong> {selectedTransaction.initial_transfer_amount}</p>
              <p><strong>Transaction Fee:</strong> {selectedTransaction.transaction_fee}</p>
              <p><strong>Total Send Amount:</strong> {selectedTransaction.total_send_amount}</p>
              <p><strong>Transaction USD Value:</strong> {selectedTransaction.transa_usd_value}</p>
              <p><strong>Status:</strong> {selectedTransaction.status}</p>
              <p><strong>Pay Out Branch:</strong> {selectedTransaction.pay_out_branch}</p>
              <p><strong>Pay Out Methods:</strong> {selectedTransaction.pay_out_methods}</p>
              <p><strong>Transaction Type:</strong> {selectedTransaction.transaction_type}</p>
              <p><strong>Transaction Reason:</strong> {selectedTransaction.transaction_reason}</p>
              <p><strong>Created At:</strong> {selectedTransaction.created_at ? new Date(selectedTransaction.created_at).toLocaleString() : 'N/A'}</p>
              <p><strong>Updated At:</strong> {selectedTransaction.updated_at ? new Date(selectedTransaction.updated_at).toLocaleString() : 'N/A'}</p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DashTrans;
