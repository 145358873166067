import React from 'react'
import DashHeader from '../Components/DashHeader'
import DashSidebar from '../Components/DashSidebar'
import DashNotifications from '../Components/DashNotifications'
import RealNav from '../Components/RealNav'
import RealSide from '../Components/RealSide'

function Notifications() {
  return (
    <div className="container-scroller">
    <RealNav className="container-fluid page-body-wrapper"/>
        <RealSide className="main-panel"/>
        <DashNotifications className="content-wrapper"/>
    
    </div>
  )
}

export default Notifications
