import React from 'react'
import FinishSignupComp from '../Components/FinishSignupComp'
import DashSidebar from '../Components/DashSidebar'
import DashHeader from '../Components/DashHeader'
function FinishSignup() {
  return (
    <div>
      <DashHeader className="container-fluid page-body-wrapper"/>
     <DashSidebar className="main-panel"/>
     <FinishSignupComp className="content-wrapper"/>
    </div>
  )
}

export default FinishSignup
