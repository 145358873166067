import React, { useState } from 'react';
import '../Auth/Login.css';
import { countries } from 'countries-list';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Spinner } from 'react-bootstrap';

export default function Signup() {
  const [selectedCountry, setSelectedCountry] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [dob, setDob] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [lastname, setLastname] = useState(''); // Optional field
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(''); // Added state for password confirmation
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State to toggle confirm password visibility
  const [gender, setGender] = useState('female');
  const [nationalId, setNationalId] = useState('');
  const [accountType, setAccountType] = useState(''); // Add account type field
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [otp, setOtp] = useState(''); // For OTP input
  const [showOtpModal, setShowOtpModal] = useState(false); // To control OTP modal visibility
  const [userId, setUserId] = useState(null); // To store the user ID after signup
  const [loading, setLoading] = useState(false); // Loading state for spinner
  const navigate = useNavigate();

  const handleCountryChange = (e) => {
    const countryCode = e.target.value;
    setSelectedCountry(countryCode);
    setPhoneNumber(`+${countries[countryCode].phone}`);
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    const digitsOnly = value.replace(/\D/g, ''); // Remove non-digit characters
    setPhoneNumber(`+${digitsOnly}`);
  };

  const handleDobChange = (e) => {
    const value = e.target.value;
    const datePattern = /^\d{4}-\d{2}-\d{2}$/; // YYYY-MM-DD format

    if (!datePattern.test(value)) {
      setErrors((prev) => ({
        ...prev,
        dob: 'Date of Birth must be in the format YYYY-MM-DD.',
      }));
    } else {
      setErrors((prev) => ({ ...prev, dob: '' }));
    }

    setDob(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading spinner

    const newErrors = {};

    if (!name) newErrors.name = 'First name is required';
    if (!surname) newErrors.surname = 'Surname is required';
    if (!dob) newErrors.dob = 'Date of Birth is required';
    if (!email) newErrors.email = 'Email is required';
    if (!phoneNumber) newErrors.phoneNumber = 'Phone number is required';
    if (!selectedCountry) newErrors.selectedCountry = 'Country is required';
    if (!nationalId) newErrors.nationalId = 'National ID is required';
    if (!password) newErrors.password = 'Password is required';
    if (!accountType) newErrors.accountType = 'Account type is required';
    if (password !== confirmPassword) newErrors.confirmPassword = 'Passwords do not match'; // Check if passwords match

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      setLoading(false); // Stop loading spinner if there are errors
      return;
    }

    const data = {
      name,
      surname,
      lastname,
      dob,
      email,
      phone1: phoneNumber,
      gender,
      country: selectedCountry,
      national_id: nationalId,
      password,
      account_type: accountType, // Add account type to data
    };

    try {
      const response = await fetch('https://api.nkolozi.com/api/register_client', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const result = await response.json();
        console.log('Signup successful:', result);
        alert('Signup successful!');

        // Store user ID
        setUserId(result.user_id);

        // Show OTP modal
        setShowOtpModal(true);
      } else {
        const errorResult = await response.json();
        console.error('Signup error response:', errorResult);

        const fieldErrors = errorResult.errors || {};

        setErrors((prev) => ({
          ...prev,
          ...fieldErrors,
        }));

        setErrorMessage(errorResult.data || 'Signup failed. Please check the fields and try again.');
        alert(errorResult.data);
      }
    } catch (error) {
      console.error('Signup exception:', error);
      setErrorMessage(`An error occurred: ${error.message}. Full error: ${JSON.stringify(error)}`);
    } finally {
      setLoading(false); // Stop loading spinner after request is done
    }
  };

  const handleOtpSubmit = async () => {
    if (!userId) {
      alert('User ID is missing');
      return;
    }

    try {
      const otpData = {
        number: otp,
        user_id: userId,
      };

      const response = await fetch('https://api.nkolozi.com/api/verify-otp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(otpData),
      });

      const contentType = response.headers.get('Content-Type');
      if (response.ok && contentType && contentType.includes('application/json')) {
        const result = await response.json();
        console.log('OTP verification successful:', result);
        alert('OTP verified successfully!');

        // Close OTP modal
        setShowOtpModal(false);

        // Navigate to the home page
        navigate('/');
      } else {
        const errorText = await response.text();
        console.error('OTP verification error response:', errorText);
        setErrorMessage('OTP verification failed. Please try again.');
        alert(errorText || 'OTP verification failed. Please try again.');
      }
    } catch (error) {
      console.error('OTP verification exception:', error);
      setErrorMessage(`An error occurred: ${error.message}. Full error: ${JSON.stringify(error)}`);
    }
  };

  return (
    <section className="vh-98 gradient-custom">
      <div className="container py-5">
        <div className="row justify-content-center align-items-center h-100">
          <div className="col-12 col-lg-9 col-xl-7">
            <div className="card shadow-2-strong card-registration" style={{ borderRadius: '1rem' }}>
              <div className="card-body p-4 p-md-5">
                <h3 style={{ textAlign: 'center' }} className="mb-4 pb-2 pb-md-0 mb-md-5">Create Account</h3>
                {errorMessage && (
                  <div className="alert alert-danger" role="alert">
                    {errorMessage}
                  </div>
                )}
                <form onSubmit={handleSubmit}>
                  {/* Spinner */}
                  {loading && (
                    <div className="text-center mb-4">
                      <Spinner animation="border" variant="primary" />
                    </div>
                  )}
                  {/* Form Fields */}
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <div className="form-outline">
                        <input
                          type="text"
                          id="name"
                          className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          placeholder="First Name"
                        />
                        {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                      </div>
                    </div>
                    <div className="col-md-6 mb-4">
                      <div className="form-outline">
                        <input
                          type="text"
                          id="lastname"
                          className="form-control"
                          value={lastname}
                          onChange={(e) => setLastname(e.target.value)}
                          placeholder="Middle Name (Optional)"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <div className="form-outline">
                        <input
                          type="text"
                          id="surname"
                          className={`form-control ${errors.surname ? 'is-invalid' : ''}`}
                          value={surname}
                          onChange={(e) => setSurname(e.target.value)}
                          placeholder="Surname"
                        />
                        {errors.surname && <div className="invalid-feedback">{errors.surname}</div>}
                      </div>
                    </div>
                    <div className="col-md-6 mb-4">
                      <div className="form-outline">
                        <input
                          type="text"
                          id="dob"
                          className={`form-control ${errors.dob ? 'is-invalid' : ''}`}
                          value={dob}
                          onChange={handleDobChange}
                          placeholder="Date of Birth (YYYY-MM-DD)"
                        />
                        {errors.dob && <div className="invalid-feedback">{errors.dob}</div>}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <div className="form-outline">
                        <input
                          type="email"
                          id="email"
                          className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Email"
                        />
                        {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                      </div>
                    </div>
                    <div className="col-md-6 mb-4">
                      <div className="form-outline">
                        <input
                          type="text"
                          id="phone1"
                          className={`form-control ${errors.phoneNumber ? 'is-invalid' : ''}`}
                          value={phoneNumber}
                          onChange={handlePhoneNumberChange}
                          placeholder="Phone Number"
                        />
                        {errors.phoneNumber && <div className="invalid-feedback">{errors.phoneNumber}</div>}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <select
                        id="country"
                        className={`form-control ${errors.selectedCountry ? 'is-invalid' : ''}`}
                        value={selectedCountry}
                        onChange={handleCountryChange}
                      >
                        <option value="">Select Country</option>
                        {Object.keys(countries).map((countryCode) => (
                          <option key={countryCode} value={countryCode}>
                            {countries[countryCode].name}
                          </option>
                        ))}
                      </select>
                      {errors.selectedCountry && <div className="invalid-feedback">{errors.selectedCountry}</div>}
                    </div>
                    <div className="col-md-6 mb-4">
                      <div className="form-outline">
                        <input
                          type="text"
                          id="nationalId"
                          className={`form-control ${errors.nationalId ? 'is-invalid' : ''}`}
                          value={nationalId}
                          onChange={(e) => setNationalId(e.target.value)}
                          placeholder="National ID"
                        />
                        {errors.nationalId && <div className="invalid-feedback">{errors.nationalId}</div>}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <select
                        id="accountType"
                        className={`form-control ${errors.accountType ? 'is-invalid' : ''}`}
                        value={accountType}
                        onChange={(e) => setAccountType(e.target.value)}
                      >
                        <option value="">Select Account Type</option>
                        <option value="personal">Personal</option>
                        <option value="business">Business</option>
                      </select>
                      {errors.accountType && <div className="invalid-feedback">{errors.accountType}</div>}
                    </div>
                    <div className="col-md-6 mb-4">
                      <select
                        id="gender"
                        className="form-control"
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                      >
                        <option value="female">Female</option>
                        <option value="male">Male</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <div className="form-outline input-group">
                        <input
                          type={showPassword ? 'text' : 'password'}
                          id="password"
                          className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="Password"
                        />
                        <div className="input-group-append">
                          <span
                            className="input-group-text"
                            onClick={() => setShowPassword(!showPassword)}
                            style={{ cursor: 'pointer' }}
                          >
                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                          </span>
                        </div>
                        {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                      </div>
                    </div>
                    <div className="col-md-6 mb-4">
                      <div className="form-outline input-group">
                        <input
                          type={showConfirmPassword ? 'text' : 'password'}
                          id="confirmPassword"
                          className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          placeholder="Confirm Password"
                        />
                        <div className="input-group-append">
                          <span
                            className="input-group-text"
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            style={{ cursor: 'pointer' }}
                          >
                            <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
                          </span>
                        </div>
                        {errors.confirmPassword && <div className="invalid-feedback">{errors.confirmPassword}</div>}
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 pt-2 text-center">
                    <button className="btn btn-primary btn-lg" type="submit" disabled={loading}>
                      {loading ? 'Registering...' : 'Submit'}
                    </button>
                  </div>
                  <div className="text-center mt-4">
                    <p>
                      Already have an account? <Link to="/login">Login here</Link>
                    </p>
                  </div>
                </form>

                {/* OTP Modal */}
                {showOtpModal && (
                  <div className="modal show d-block">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">OTP Verification</h5>
                          <button
                            type="button"
                            className="close"
                            onClick={() => setShowOtpModal(false)}
                          >
                            <span>&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <p>Please enter the OTP sent to your phone:</p>
                          <input
                            type="text"
                            className="form-control"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            placeholder="Enter OTP"
                          />
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleOtpSubmit}
                          >
                            Verify OTP
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
