import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';

function FullTransComp() {
  const [transactions, setTransactions] = useState([]);
  const [beneficiaries, setBeneficiaries] = useState({});
  const authToken = Cookies.get('authToken');

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await fetch('https://api.nkolozi.com/api/Client/client-transactions', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
          }
        });

        const result = await response.json();
        if (response.ok) {
          setTransactions(result.data);
        } else {
          console.log('Failed to fetch transactions:', result);
        }
      } catch (error) {
        console.log('An error occurred:', error);
      }
    };

    const fetchBeneficiaries = async () => {
      try {
        const response = await fetch('https://api.nkolozi.com/api/Client/beneficiaries-accounts', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
          }
        });

        const result = await response.json();
        if (response.ok) {
          const beneficiariesMap = {};
          result.data.forEach(beneficiary => {
            beneficiariesMap[beneficiary.id] = beneficiary.full_name;
          });
          setBeneficiaries(beneficiariesMap);
        } else {
          console.log('Failed to fetch beneficiaries:', result);
        }
      } catch (error) {
        console.log('An error occurred:', error);
      }
    };

    fetchTransactions();
    fetchBeneficiaries();
  }, [authToken]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body px-0 overflow-auto">
              <h4 className="card-title pl-4">Remittance Transactions</h4>
              <div className="table-responsive">
                {transactions.length > 0 ? (
                  <table className="table">
                    <thead className="bg-light">
                      <tr>
                        <th>Transaction ID</th>
                        <th>Recipient</th>
                        <th>Status</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactions.map(transaction => (
                        <tr key={transaction.id}>
                          <td>{transaction.transaction_id}</td>
                          <td>{beneficiaries[transaction.beneficiary_id] || 'Unknown'}</td>
                          <td>
                            <div className={`badge badge-${transaction.status === 'completed' ? 'inverse-success' : 'inverse-danger'}`}>
                              {transaction.status.charAt(0).toUpperCase() + transaction.status.slice(1)}
                            </div>
                          </td>
                          <td>${transaction.total_send_amount.toFixed(2)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="text-center py-4">
                    <h5>No Transactions Yet</h5>
                  </div>
                )}
              </div>
              <Link to="/trans" className="text-black mt-3 d-block pl-4">
  <span className="font-weight-medium h6">View all transaction history</span>
  <i className="mdi mdi-chevron-right" />
</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FullTransComp;
