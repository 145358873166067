import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './ReceiverInfo.css';
import Cookies from 'js-cookie';
import { Paper, Typography } from '@mui/material';  
import { Modal, Button, Table } from 'react-bootstrap';
import { countries } from 'countries-list';

function ReceiverInfo() {
  
  const [showReceiverForm, setShowReceiverForm] = useState(false);
  const [showBeneficiaries, setShowBeneficiaries] = useState(false);
  const [showDeposit, setShowDeposit] = useState(false);
  const [showTransaction, setShowTransaction] = useState(false)
  const [formData, setFormData] = useState({
    full_name: '',
    national_id: '',
    relationship: '',
    delivery_methods: '',
    pay_out_branch: '',
    country: '',
    address: '',
    phone: '',
    reason_for_remittance: ''
  });
  const [responseMessage, setResponseMessage] = useState('');
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [editingBeneficiary, setEditingBeneficiary] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchBeneficiaries = async () => {
        const authToken = Cookies.get('authToken');
        if (!authToken) {
            setResponseMessage('User not authenticated. Please log in.');
            return;
        }

        try {
            const response = await fetch('https://api.nkolozi.com/api/Client/beneficiaries', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                }
            });

            const data = await response.json();
            console.log(data);

            if (response.ok) {
                // Since the API returns an array directly, set it without accessing a `data` field
                setBeneficiaries(data.beneficiaries);
                console.log(data.beneficiaries);
            } else {
                setResponseMessage(`Failed to fetch beneficiaries: ${data.message}`);
            }
        } catch (error) {
            setResponseMessage('An error occurred while fetching beneficiaries. Please try again later.');
        }
    };

    fetchBeneficiaries();
}, []);


  const handleReceiverCardClick = () => {
    setShowReceiverForm(!showReceiverForm);
  };

  const handleBeneficiariesCardClick = () => {
    setShowBeneficiaries(!showBeneficiaries);
  };

  const handleTransactionCardClick = () => {
    setShowTransaction(!showTransaction);
  };

  const navigateToTransaction = () => {
    navigate('/transact');
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const handleCountryChange = (e) => {
    const country = e.target.value;
    const countryCode = countries[country]?.phone || '';
    setFormData((prevData) => ({
      ...prevData,
      country: country,
      phone: `+${countryCode}`
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const authToken = Cookies.get('authToken');
    if (!authToken) {
      setResponseMessage('User not authenticated. Please log in.');
      return;
    }

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const user_id = userInfo?.id;

    if (!user_id) {
      setResponseMessage('User ID not found. Please login again.');
      return;
    }

    const requestData = {
      user_id: user_id,
      full_name: formData.full_name,
      national_id: formData.national_id,
      relationship: formData.relationship,
      delivery_methods: formData.delivery_methods,
      pay_out_branch: formData.pay_out_branch,
      country: formData.country,
      address: formData.address,
      phone: formData.phone,
      reason_for_remittance: formData.reason_for_remittance
    };

    try {
      const response = await fetch('https://api.nkolozi.com/api/Client/add-beneficiary ', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify(requestData)
      });

      const data = await response.json();
      if (response.ok) {
        setResponseMessage('Beneficiary account added successfully.');
        setBeneficiaries((prevBeneficiaries) => [
          ...prevBeneficiaries,
          { id: data.id, name: formData.full_name }
        ]);
      } else {
        setResponseMessage(`Failed: ${data.message}`);
      }
    } catch (error) {
      setResponseMessage('An error occurred. Please try again later.');
    }
  };

  const handleEditBeneficiary = (beneficiary) => {
    setFormData({
      full_name: beneficiary.full_name,
      national_id: beneficiary.national_id,
      relationship: beneficiary.relationship,
      delivery_methods: beneficiary.delivery_methods,
      pay_out_branch: beneficiary.pay_out_branch,
      country: beneficiary.country,
      address: beneficiary.address,
      phone: beneficiary.phone,
      reason_for_remittance: beneficiary.reason_for_remittance
    });
    setEditingBeneficiary(beneficiary);
    setShowReceiverForm(true);
  };

  const handleUpdateBeneficiary = async () => {
    const authToken = Cookies.get('authToken');
    if (!authToken) {
      setResponseMessage('User not authenticated. Please log in.');
      return;
    }

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const user_id = userInfo?.id;

    if (!user_id) {
      setResponseMessage('User ID not found. Please login again.');
      return;
    }

    const requestData = {
      user_id: user_id,
      beneficiary_id: editingBeneficiary.id,
      full_name: formData.full_name,
      national_id: formData.national_id,
      relationship: formData.relationship,
      delivery_methods: formData.delivery_methods,
      pay_out_branch: formData.pay_out_branch,
      country: formData.country,
      address: formData.address,
      phone: formData.phone,
      reason_for_remittance: formData.reason_for_remittance
    };

    try {
      const response = await fetch('https://api.nkolozi.com/api/Client/update-beneficiary-account', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify(requestData)
      });

      const data = await response.json();
      if (response.ok) {
        setResponseMessage('Beneficiary account updated successfully.');
        setBeneficiaries((prevBeneficiaries) =>
          prevBeneficiaries.map((b) => (b.id === editingBeneficiary.id ? { ...b, ...formData } : b))
        );
        setEditingBeneficiary(null);
        setShowReceiverForm(false);
      } else {
        setResponseMessage(`Failed: ${data.message}`);
      }
    } catch (error) {
      setResponseMessage('An error occurred. Please try again later.');
    }
  };

  const handleBeneficiaryClick = (beneficiary) => {
    navigate('/transact', { state: { beneficiary } });
  };

  return (
    <div className="main" style={{marginLeft:"38%"}}>
      <Paper
        sx={{
          padding: '3px 10px', 
          marginBottom: '20px',
          width: 'fit-content', // Makes the paper fit the content
          backgroundColor: '#c5401f', // Set background color
          '@keyframes slide-up': {
            '0%': {
              transform: 'translateY(20px)',
              opacity: 0,
            },
            '100%': {
              transform: 'translateY(0)',
              opacity: 1,
            },
          },
          animation: 'slide-up 0.5s ease-in-out',
        }}
      >
        <Typography variant="h5" gutterBottom sx={{ color: '#fff' }}>
          Receiver Information 
        </Typography>
      </Paper>
      <p>Know your receiver. After your money has been picked up, it can’t be refunded.</p>
      <p>A Nkolozi agent will check your receiver’s picture ID against what you enter below. Be sure to enter the information exactly as it appears on your receiver’s ID.</p>
      <p>Important: If your receiver’s ID shows a middle name, you must include it below.</p>

      <div className={`receiver-card ${showReceiverForm ? 'expanded' : ''}`} onClick={handleReceiverCardClick}>
        <div className="card-header">
          <i className="fas fa-user-plus"></i>
          <h3>{editingBeneficiary ? 'Edit Receiver' : 'Add New Receiver'}</h3>
        </div>

        {showReceiverForm && (
          <div className="card-body">
            <div className="form-group">
              <input
                type="text"
                id="full_name"
                name="full_name"
                placeholder="Full Name"
                value={formData.full_name}
                onChange={handleInputChange}
                onClick={stopPropagation}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                id="national_id"
                name="national_id"
                placeholder="National ID"
                value={formData.national_id}
                onChange={handleInputChange}
                onClick={stopPropagation}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                id="relationship"
                name="relationship"
                placeholder="Relationship"
                value={formData.relationship}
                onChange={handleInputChange}
                onClick={stopPropagation}
                required
              />
            </div>
            
            <div className="form-group">
              <select
                id="country"
                name="country"
                value={formData.country}
                onChange={handleCountryChange}
                onClick={stopPropagation}
                required
              >
                <option value="" disabled>
                  Select Country
                </option>
                {Object.keys(countries).map((countryCode) => (
                  <option key={countryCode} value={countryCode}>
                    {countries[countryCode].name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <input
                type="text"
                id="address"
                name="address"
                placeholder="Address"
                value={formData.address}
                onChange={handleInputChange}
                onClick={stopPropagation}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                id="phone"
                name="phone"
                placeholder="Phone"
                value={formData.phone}
                onChange={handleInputChange}
                onClick={stopPropagation}
                required
              />
            </div>
           
            <button className="btn btn-primary" onClick={editingBeneficiary ? handleUpdateBeneficiary : handleSubmit}>
              {editingBeneficiary ? 'Update Beneficiary' : 'Add Beneficiary'}
            </button>
            {responseMessage && <p className="response-message">{responseMessage}</p>}
          </div>
        )}
      </div>

      <div className={`beneficiaries-card ${showBeneficiaries ? 'expanded' : ''}`} onClick={handleBeneficiariesCardClick}>
        <div className="card-header">
          <i className="fas fa-users"></i>
          <h3>My Beneficiaries</h3>
        </div>
        {showBeneficiaries && (
          <div className="card-body">
            {beneficiaries.length === 0 ? (
              <p>No beneficiaries found.</p>
            ) : (
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Full Name</th>
                    <th>National ID</th>
                    <th>Relationship</th>
                    <th>Phone</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {beneficiaries.map((beneficiary) => (
                    <tr key={beneficiary.id}>
                      <td>{beneficiary.full_name}</td>
                      <td>{beneficiary.national_id}</td>
                      <td>{beneficiary.relationship}</td>
                      <td>{beneficiary.phone}</td>
                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() => handleEditBeneficiary(beneficiary)}
                        >
                          Edit
                        </button>
                        <button
                          className="btn btn-success"
                          onClick={() => handleBeneficiaryClick(beneficiary)}
                        >
                          Transact
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </div>
        )}
      </div>


{/* Transaction */}

<div className={`beneficiaries-card ${showTransaction ? 'expanded' : ''}`} onClick={handleTransactionCardClick}>
        <div className="card-header">
          <i className="fas fa-sack-dollar"></i>
          <h3>Make Transaction</h3>
        </div>
        {showTransaction && (
          <div className="card-body">
            <button onClick={navigateToTransaction}>Make Transaction</button>
          </div>
        )}
      </div>
      
    </div>
  );
}

export default ReceiverInfo;
